import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../../../_metronic/layout/core";
import StatusPage from "./StatusPage";

const StatusPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="status"
          element={
            <>
              <PageTitle breadcrumbs={[]}>Procore - Status</PageTitle>
              <StatusPage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/procore/status" />} />
    </Routes>
  );
};

export default StatusPageWrapper;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { countertops } from "../../modules/auth/core/_requests";
import {
  getCounterTops,
  setCounterTops,
} from "../../modules/auth/core/InventoryHelpers";
import { KTSVG } from "../../../_metronic/helpers";
import { Button, Modal } from "react-bootstrap";
import CountertopsInteractive from "./interactive_module/CountertopsInteractive";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
  coutertops?: any;
};

let total: number = 0;

const ConterTopsPage: React.FC<Props> = ({
  className,
  page,
  invoiceType,
  coutertops,
}) => {
  const [counterTops, setCounterTopsData]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);

  const fetchCounterTopsData = async () => {
    let CounterTopsData = await countertops();
    setCounterTops(CounterTopsData.data.result);
    let localCounterTops = getCounterTops();
    setCounterTopsData(localCounterTops);
  };

  useEffect(() => {
    let localCounterTops = getCounterTops();
    if (invoiceTypeMode === "edit") {
      let localStorageCounterTops: any = localStorage.getItem("counter_tops");
      let tempSavedCounterTops: any = JSON.parse(localStorageCounterTops);
      total = tempSavedCounterTops?.total;
      setCounterTopsData(tempSavedCounterTops?.data);
    } else {
      if (!localCounterTops) {
        fetchCounterTopsData();
      } else {
        if (coutertops && coutertops.length) {
          setCounterTopsData(coutertops);
        } else {
          setCounterTopsData(localCounterTops);
        }
      }
    }
  }, [coutertops]); // eslint-disable-line

  const handleChange = (event: string, index: number) => {
    let clone = [...counterTops];
    let obj = clone[index];
    for (let i = 0; i < obj.option_selected.length; i++) {
      if (obj.option_selected[i].option === event) {
        obj.sf_cost = obj.option_selected[i].sf_cost;
        // obj.cost = 0;
        obj.selectedOption = obj.option_selected[i].option;
        obj.cost = obj.sf_cost * obj.area_sf;
        obj.option_selected[i].selected = true;
      } else {
        obj.option_selected[i].selected = false;
      }
    }
    clone[index] = obj;
    setCounterTopsData([...clone]);
  };

  const calculateCost = (event: string, index: number) => {
    const area: number = Number(event);
    let clone = [...counterTops];
    let obj = clone[index];
    obj.area_sf = area;
    obj.cost = obj.sf_cost * area;
    let tempTotal: number = 0;

    clone[index] = obj;
    setCounterTopsData([...clone]);

    // let selectedItem = [];

    // for (let i = 0; i < counterTops.length; i++) {
    //   selectedItem.push(counterTops[i])
    //   let localstorageData = {
    //     total: total,
    //     data: selectedItem
    //   }
    //   localStorage.setItem('counter_tops', JSON.stringify(localstorageData));
    // }

    let localstorageData = {
      total: total,
      data: counterTops,
    };
    localStorage.setItem("counter_tops", JSON.stringify(localstorageData));

    let localStorageCounterTops: any = localStorage.getItem("counter_tops");
    let tempSavedCounterTops: any = JSON.parse(localStorageCounterTops);
    let savedCounterTops: any = tempSavedCounterTops.data;
    for (let i = 0; i < savedCounterTops.length; i++) {
      if (savedCounterTops[i].cost && savedCounterTops[i].cost > 0) {
        if (savedCounterTops[i]?.cost) {
          tempTotal = tempTotal + savedCounterTops[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedCounterTops,
      };
      localStorage.setItem("counter_tops", JSON.stringify(localstorageData));
    }
  };

  // Search Function : Begins

  const [query, setQuery] = useState("");

  // Search Function : Ends

  // Interactive Module Pop Up : Starts

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleModalClose = () => {
    let tempTotal: number = 0;
    let localStorageCounterTops: any = localStorage.getItem("counter_tops");
    let tempSavedCounterTops: any = JSON.parse(localStorageCounterTops);
    let savedCounterTops: any = tempSavedCounterTops.data;
    for (let i = 0; i < savedCounterTops.length; i++) {
      if (savedCounterTops[i].cost && savedCounterTops[i].cost > 0) {
        if (savedCounterTops[i]?.cost) {
          tempTotal = tempTotal + savedCounterTops[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedCounterTops,
      };
      localStorage.setItem("counter_tops", JSON.stringify(localstorageData));
    }
    setCounterTopsData(tempSavedCounterTops?.data);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // Interactive Module Pop Up : Ends

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Counter Tops</span>
          </h3>
          {/* Interactive Modal : Begins */}
          <Button variant="primary" onClick={handleShow}>
            Choose Interactive Mode
          </Button>
          <Modal show={show} onHide={handleClose} fullscreen={true}>
            <Modal.Header closeButton>
              <Modal.Title>Countertops Interactive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {counterTops.length ? (
                <CountertopsInteractive
                  counterTops={counterTops}
                  handleModalClose={handleModalClose}
                />
              ) : null}
              {/* <CountertopsInteractive /> */}
            </Modal.Body>
          </Modal>
          {/* Interactive Modal : Ends */}
        </div>
      )}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin:: Table Search Box */}
        {page === "invoice" ? null : (
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1  pt-10 pb-10">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Search countertops"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        )}
        {/* end:: Table Search Box */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-120px">Area</th>
                <th className="min-w-120px">Counter Selection</th>
                <th className="min-w-120px">Edge Details</th>
                <th className="min-w-120px">Option Selected</th>
                <th className="min-w-120px" style={{ width: "10%" }}>
                  SF cost
                </th>

                {page === "invoice" && (
                  <>
                    <th className="min-w-80px">Area (SF)</th>
                    <th className="min-w-80px" style={{ width: "10%" }}>
                      Cost
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {counterTops?.length &&
                counterTops
                  ?.filter((data: { area: string }) =>
                    data?.area.toLowerCase().includes(query)
                  )
                  .map((data: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.area}
                        </a>
                      </td>

                      <td className="text-start">
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.counter_selection}
                        </a>
                      </td>

                      <td className="text-start">
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.edge_detail}
                        </a>
                      </td>

                      <td>
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          defaultValue={
                            data?.selectedOption ||
                            data?.option_selected[0].option
                          }
                          onChange={(e) => handleChange(e.target.value, index)}
                          key={index}
                        >
                          {data?.option_selected.map(
                            (options: any, index: number) => (
                              <option
                                key={index}
                                value={options.option}
                                selected={
                                  options?.option === data?.selectedOption
                                }
                              >
                                {options.option}
                              </option>
                            )
                          )}
                        </select>
                      </td>

                      <td className="text-start">
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          ${data?.sf_cost || data?.option_selected[0].sf_cost}
                        </a>
                      </td>

                      {page === "invoice" && (
                        <>
                          <td className="text-start">
                            <input
                              type="number"
                              min={0}
                              // data-kt-user-table-filter='search'
                              className="form-control form-control-solid w-150px ps-14"
                              placeholder="Area (SF)"
                              // value={searchTerm}
                              value={data?.area_sf || ""}
                              onChange={(e) =>
                                calculateCost(e.target.value, index)
                              }
                            />
                          </td>
                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              ${data?.cost ? data?.cost?.toFixed(2) : 0}
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}

              {page === "invoice" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 600 }} className="text-center">
                      Total
                    </td>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                        style={{ paddingLeft: "2.5%" }}
                      >
                        ${total?.toFixed(2) || 0}
                      </a>
                    </td>
                  </tr>
                  {/* <tr style={{ marginTop: "10px" }}>
                  <td colSpan={3}>
                    <FormInput type='text' className='form-control form-control-solid' placeholder='Please enter comment here' onChange={(e) => handleComment(e.target.value)} />
                  </td>
                </tr> */}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { ConterTopsPage };

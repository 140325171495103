import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../_metronic/helpers";
import {
  FetchInvoiceQueryResponse,
  Invoice,
  FetchSiteMappingQueryResponse,
} from "./_models";
// import {Invoice, UsersQueryResponse} from './_models'
// import {invoices} from '../../../../modules/auth/core/Data'

const API_URL = process.env.REACT_APP_API_URL;
const PROCORE_API_URL = process.env.REACT_APP_PROCORE_API_URL;
const USER_URL = `${API_URL}/user`;
const INVOICE_URL = `${API_URL}/invoice`;

export const CREATE_INVOICE_URL = `${API_URL}/invoice`;
export const REQUEST_INVOICES_URL = `${API_URL}/invoices`;
export const REQUEST_INVOICE_URL = `${API_URL}/invoice`;
export const UPDATE_INVOICE_URL = `${API_URL}/invoice`;
export const DELETE_INVOICE_URL = `${API_URL}/invoice`;

export const GET_SITE_MAPPING_URL = `${API_URL}/site/maps`;
export const UPLOAD_INVOICE = `${PROCORE_API_URL}/procore/upload`;

const getSiteMapping = (siteId: string) => {
  return axios
    .get(`${GET_SITE_MAPPING_URL}/${siteId}`)
    .then((d: AxiosResponse<FetchSiteMappingQueryResponse>) => d.data);
};

const createInvoice = (customerId: string, createInvoiceFormdata: any) => {
  const createInvoiceHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "list-all-invoice",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.post<Invoice>(
    `${CREATE_INVOICE_URL}/${customerId}`,
    createInvoiceFormdata,
    {
      headers: createInvoiceHeaders,
    }
  );
};

const getInvoices = () => {
  return axios
    .get(REQUEST_INVOICES_URL)
    .then((d: AxiosResponse<FetchInvoiceQueryResponse>) => d.data);
};

const getInvoiceById = async (id: ID) => {
  let result = await axios.get(`${REQUEST_INVOICE_URL}/${id}`);
  return result.data.result;
};

const updateInvoiceApi = (customerId: string, updateInvoiceFormdata: any) => {
  const updateInvoiceHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "update-invoice",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.put<Invoice>(
    `${UPDATE_INVOICE_URL}/${customerId}`,
    updateInvoiceFormdata,
    {
      headers: updateInvoiceHeaders,
    }
  );
};

const uploadInvoicePdf = (uploadInvoiceFormdata: any) => {
  const uploadInvoiceHeaders = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  };
  return axios.post<Invoice>(`${UPLOAD_INVOICE}`, uploadInvoiceFormdata, {
    headers: uploadInvoiceHeaders,
  });
};

const deleteInvoice = (invoiceId: any): Promise<void> => {
  return axios.delete(`${DELETE_INVOICE_URL}/${invoiceId}`).then(() => {});
};

// const getInvoices = (query: string): Promise<UsersQueryResponse> => {
//   return axios
//     .get(`${GET_USERS_URL}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

// const getInvoices = () => {
//   return invoices;
//   // return axios
//   //   .get(`${GET_USERS_URL}?${query}`)
//   //   .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

// const getInvoices = () => {

//   return Invoices;
//   //TODO: uncomment this and fetch data from real countertops api
//   // return axios.post<AuthModel>(REQUEST_COUNTERTOPS_URL, {
//   //   email,
//   //   password,
//   // })
// }

const getUserById = (id: ID): Promise<Invoice | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data);
};

const createUser = (user: Invoice): Promise<Invoice | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data);
};

const updateUser = (user: Invoice): Promise<Invoice | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data);
};

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {});
};

const deleteSelectedInvoices = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${INVOICE_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

export {
  createInvoice,
  getInvoiceById,
  updateInvoiceApi,
  getInvoices,
  deleteInvoice,
  deleteUser,
  deleteSelectedInvoices,
  getUserById,
  createUser,
  updateUser,
  getSiteMapping,
  uploadInvoicePdf,
};

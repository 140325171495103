import axios from "axios";
import { Sync } from "./_models";

const API_URL = process.env.REACT_APP_PROCORE_API_URL;

export const SYNC_PROCORE_URL = `${API_URL}/procore/sync`;

const procoreHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
};

export function SyncProcoreCompany(type: "company") {
  return axios.post<Sync>(
    SYNC_PROCORE_URL,
    {
      type,
    },
    {
      headers: procoreHeaders,
    }
  );
}

export function SyncProcoreProject(type: "project") {
  return axios.post<Sync>(
    SYNC_PROCORE_URL,
    {
      type,
    },
    {
      headers: procoreHeaders,
    }
  );
}

export function SyncProcoreUser(type: "user") {
  return axios.post<Sync>(
    SYNC_PROCORE_URL,
    {
      type,
    },
    {
      headers: procoreHeaders,
    }
  );
}

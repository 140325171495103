import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { ElectricalPage } from "./ElectricalPage";

const ElectricalPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Electrical</PageTitle>
      <ElectricalPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default ElectricalPageWrapper;

type Props = {
  alertMessage: string;
  alertType: string;
};

const Alert: React.FC<Props> = ({ alertMessage, alertType }) => {
  return (
    <div
      className={`alert alert-${alertType} d-flex align-items-center p-5 mb-10`}
    >
      <div className="d-flex flex-column">
        <span>{alertMessage}</span>
      </div>
    </div>
  );
};

export default Alert;

import { ListViewProvider } from "../../../site/sites-list/core/ListViewProvider";
import { QueryRequestProvider } from "../../../site/sites-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "../../../site/sites-list/core/QueryResponseProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getProcoreConfiguration,
  saveProcoreConfiguration,
} from "./core/_request";
import React, { useEffect, useState } from "react";
import { getUserAccess } from "../../../../modules/auth";

const initialValues = {
  ClientId: "",
  ClientSecret: "",
  CompanyId: "",
};

const validationSchema = Yup.object().shape({
  ClientId: Yup.string().required("Please Enter Client ID"),
  ClientSecret: Yup.string().required("Please Enter Client Secret"),
  CompanyId: Yup.string().required("Please Enter Company ID"),
});

const ConfigurationPage = () => {
  const [procoreData, setProcoreData]: any = React.useState([]);
  const [success, setSuccess] = useState(false);

  const adminID = getUserAccess();

  const getProcoreData = async () => {
    let { data: getProcore } = await getProcoreConfiguration(adminID?.id);
    setProcoreData(getProcore);
  };

  useEffect(() => {
    getProcoreData();
  }, []); // eslint-disable-line

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: procore } = await saveProcoreConfiguration(
          values.ClientId,
          values.ClientSecret,
          values.CompanyId
        );
        setStatus(procore.message);
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setStatus("Something went wrong");
        setSuccess(false);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <div className="d-flex flex-center bg-white">
            <div className="py-20 mw-200 px-9">
              <form onSubmit={formik.handleSubmit}>
                {formik.status ? (
                  <div
                    className={`mb-lg-15 alert alert-${
                      success ? "success" : "danger"
                    }`}
                  >
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : null}
                <div className="mb-10">
                  <label htmlFor="ClientId" className="required form-label">
                    Client ID
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("ClientId")}
                    className="form-control form-control-solid"
                    placeholder="Paste here"
                    value={procoreData.client_id}
                    disabled={procoreData.length === 0 ? false : true}
                  />
                  {formik.touched.ClientId && formik.errors.ClientId ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.ClientId}
                    </span>
                  ) : null}
                </div>
                <div className="mb-10">
                  <label htmlFor="ClientSecret" className="required form-label">
                    Client Secret
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("ClientSecret")}
                    className="form-control form-control-solid"
                    placeholder="Paste here"
                    value={procoreData.client_secret}
                    disabled={procoreData.length === 0 ? false : true}
                  />
                  {formik.touched.ClientSecret && formik.errors.ClientSecret ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.ClientSecret}
                    </span>
                  ) : null}
                </div>
                <div className="mb-10">
                  <label htmlFor="CompanyId" className="required form-label">
                    Company ID
                  </label>
                  <input
                    type="text"
                    {...formik.getFieldProps("CompanyId")}
                    className="form-control form-control-solid"
                    placeholder="Paste here"
                    value={procoreData.company_id}
                    disabled={procoreData.length === 0 ? false : true}
                  />
                  {formik.touched.CompanyId && formik.errors.CompanyId ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.CompanyId}
                    </span>
                  ) : null}
                </div>
                <div className="mb-10">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary col-12"
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      procoreData.length === 0
                        ? false
                        : true
                    }
                  >
                    {!loading && (
                      <span className="indicator-label">
                        Save Configuration
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export default ConfigurationPage;

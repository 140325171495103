import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { NewUserModel, AuthModel, UserAccesModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import * as inventoryHelper from "./InventoryHelpers";
import { decodeUserByToken } from "./_requests";
import { Logout } from "../Logout";

type AuthContextProps = {
  auth: AuthModel | undefined;
  access: UserAccesModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  saveUserAccess: (access: UserAccesModel | undefined) => void;
  currentUser: NewUserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<NewUserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  access: authHelper.getUserAccess(),
  saveAuth: () => {},
  saveUserAccess: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [access, setAccess] = useState<UserAccesModel | undefined>(
    authHelper.getUserAccess()
  );
  const [currentUser, setCurrentUser] = useState<NewUserModel | undefined>();

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    }
  };

  const saveUserAccess = (access: UserAccesModel | undefined) => {
    setAccess(access);
    if (access) {
      authHelper.setUserAccess(access);
    }
  };

  const logout = () => {
    authHelper.removeAuth();
    authHelper.removeUserAcces();
    authHelper.removeLicense();
    localStorage.removeItem("invoice_site_map");
    localStorage.removeItem("syncProcoreCompanyData");
    localStorage.removeItem("syncProcoreProjectData");
    localStorage.removeItem("syncProcoreUserData");
    inventoryHelper.removeAppliances();
    inventoryHelper.removeCounterTops();
    inventoryHelper.removeElectrical();
    inventoryHelper.removeHardwood();
    inventoryHelper.removePlumbingFinishes();
    inventoryHelper.removeTile();
    saveAuth(undefined);
    saveUserAccess(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        access,
        saveUserAccess,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC = ({ children }) => {
  const { auth, access, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const data = await decodeUserByToken(apiToken);
          if (data.exp * 1000 < Date.now()) {
            logout();
            return <Logout />;
          }
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };
    if (auth && auth.result) {
      requestUser(auth.result);
    } else if (auth && auth.result && access) {
      requestUser(auth.result);
    } else {
      logout();
      setShowSplashScreen(false);
    }

    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };

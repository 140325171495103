import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { AppliancesPage } from "./AppliancesPage";

const AppliancesPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Appliances</PageTitle>
      <AppliancesPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default AppliancesPageWrapper;

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../../../_metronic/layout/core";
import ConfigurationPage from "./ConfigurationPage";

const ConfigurationPageWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="configuration"
          element={
            <>
              <PageTitle breadcrumbs={[]}>Procore - Configuration</PageTitle>
              <ConfigurationPage />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/procore/configuration" />} />
    </Routes>
  );
};

export default ConfigurationPageWrapper;

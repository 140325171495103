import jsPDF from "jspdf";
import html2canvas from "html2canvas";

interface PdfProps {
  reportTemplateRef: any;
  invoiceData: any;
  fileName: any;
  format: any;
  orientation: any;
  scale: number;
  download?: boolean;
}

export const generatePdf = async ({
  reportTemplateRef,
  invoiceData,
  fileName,
  format,
  orientation,
  scale,
  download = false,
}: PdfProps) => {
  const doc = new jsPDF({
    format,
    orientation,
    unit: "px",
    compress: true,
  });

  if (reportTemplateRef.current !== null) {
    // const pdfWidth = doc.internal.pageSize.getWidth();
    // const pdfHeight = doc.internal.pageSize.getHeight();

    const canvas = await html2canvas(reportTemplateRef.current, { scale });
    const imgData = canvas.toDataURL("image/png");

    const imgWidth = 210;
    const pageHeight = 295;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    while (heightLeft >= 0) {
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    const generatedPdfBytes = doc.output("arraybuffer");

    const modifiedPdfBlob = new Blob([generatedPdfBytes], {
      type: "application/pdf",
    });

    if (download) {
      doc.save(fileName);
    }

    return modifiedPdfBlob;
  }
};

import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { PlumbingFinishesPage } from "./PlumbingFinishesPage";

const PlumbingFinishesPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Plumbing Finishes</PageTitle>
      <PlumbingFinishesPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default PlumbingFinishesPageWrapper;

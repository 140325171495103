import axios from "axios";
import { invoices, procoreStatus } from "./Data";
import {
  UserModel,
  AuthModel,
  DecodeTokenResponse,
  UserAccesModelResponse,
  HardwoodResponseQuery,
  ElectricalResponseQuery,
  PlumbingFinishesResponseQuery,
  CounterTopsResponseQuery,
  TileResponseQuery,
  AppliancesResponseQuery,
  ForgotPasswordModel,
  PingModel,
} from "./_models";
import jwt_decode from "jwt-decode";
import { getAuth } from "./AuthHelpers";

const API_URL = process.env.REACT_APP_API_URL;
const PROCORE_API_URL = process.env.REACT_APP_PROCORE_API_URL;
const TOKEN = getAuth();

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const PROCORE_LOGIN_URL = `${PROCORE_API_URL}/auth/login`;
export const PING_URL = `${API_URL}/ping`;
export const CUSTOMER_LOGIN_URL = `${API_URL}/customer/login`;
export const ACCESS_URL = `${API_URL}/access`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/send_reset_passwd_link`;
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/reset_passwd_api`;
export const REQUEST_HARDWOOD_URL = `${API_URL}/inventory/hardwood`;
export const REQUEST_ELECTRICAL_URL = `${API_URL}/inventory/electrical`;
export const REQUEST_PLUMBING_FINISHES_URL = `${API_URL}/inventory/plumbing_finishes`;
export const REQUEST_COUNTERTOPS_URL = `${API_URL}/inventory/countertops`;
export const REQUEST_TILE_URL = `${API_URL}/inventory/tile`;
export const REQUEST_APPLIANCES_URL = `${API_URL}/inventory/appliances`;

const headers = {
  "Content-Type": "application/json",
  "request-id": "site-repo",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
};

const privateRouteHeaders = {
  "Content-Type": "application/json",
  "request-id": "site-repo",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
  Authorization: TOKEN?.result,
};

const privateForgotPasswordRouteHeaders = {
  "Content-Type": "multipart/form-data",
  "request-id": "send-reset-password-link",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
};

export function procoreLogin(accessToken: string) {
  return axios.post<AuthModel>(
    PROCORE_LOGIN_URL,
    {
      procoreAccessToken: accessToken,
    },
    {
      headers: headers,
    }
  );
}

// Server should return AuthModel

export function login(username: string, password: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      username,
      password,
    },
    {
      headers: headers,
    }
  );
}

export function ping(token: string) {
  const pingRouteHeaders = {
    "Content-Type": "application/json",
    "request-id": "site-repo",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
    Authorization: token,
  };
  return axios.get<PingModel>(PING_URL, {
    headers: pingRouteHeaders,
  });
}

export function customerLogin(username: string, password: string) {
  return axios.post<AuthModel>(
    CUSTOMER_LOGIN_URL,
    {
      username,
      password,
    },
    {
      headers: headers,
    }
  );
}

// forgot password

export function forgotPassword(createForgotPasswordFormdata: any) {
  return axios.post<ForgotPasswordModel>(
    REQUEST_PASSWORD_URL,
    createForgotPasswordFormdata,
    {
      headers: privateForgotPasswordRouteHeaders,
    }
  );
}

// reset password

export function resetPassword(createResetPasswordFormdata: any, token: string) {
  const privateResetPasswordRouteHeaders = {
    "Content-Type": "multipart/form-data",
    "request-id": "reset-password",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
    Authorization: token,
  };
  return axios.post<ForgotPasswordModel>(
    REQUEST_RESET_PASSWORD_URL,
    createResetPasswordFormdata,
    {
      headers: privateResetPasswordRouteHeaders,
    }
  );
}

// Get all the access related data of a user

export function access() {
  return axios.get<UserAccesModelResponse>(ACCESS_URL, {
    headers: privateRouteHeaders,
  });
}

// Server should return counter tops
export function countertops() {
  return axios.get<CounterTopsResponseQuery>(REQUEST_COUNTERTOPS_URL, {
    headers: privateRouteHeaders,
  });
}

// Server should return Electrical
export function electrical() {
  return axios.get<ElectricalResponseQuery>(REQUEST_ELECTRICAL_URL, {
    headers: privateRouteHeaders,
  });
}

// Server should return Plumbing Finishes
export function plumbing_finishes() {
  return axios.get<PlumbingFinishesResponseQuery>(
    REQUEST_PLUMBING_FINISHES_URL,
    {
      headers: privateRouteHeaders,
    }
  );
}

// Server should return Hardwood
export function hardwood() {
  return axios.get<HardwoodResponseQuery>(REQUEST_HARDWOOD_URL);
}

// Server should return Tiles
export function tiles() {
  return axios.get<TileResponseQuery>(REQUEST_TILE_URL, {
    headers: privateRouteHeaders,
  });
}

// Server should return Appliances
export function appliance() {
  return axios.get<AppliancesResponseQuery>(REQUEST_APPLIANCES_URL, {
    headers: privateRouteHeaders,
  });
}

// Server should return Invoices
export function invoice() {
  return invoices;
  //TODO: uncomment this and fetch data from real electrical api
  // return axios.post<AuthModel>(REQUEST_ELECTRICAL_URL, {
  //   email,
  //   password,
  // })
}

// Server should return Procore Status
export function procore_status() {
  return procoreStatus;
  //TODO: uncomment this and fetch data from real procore status api
  // return axios.post<AuthModel>(REQUEST_PROCORE_URL, {
  //   email,
  //   password,
  // })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}

export async function decodeUserByToken(token: string) {
  let data = await jwt_decode<DecodeTokenResponse>(token);
  let newDecoded;
  if (!data.data.full_name) {
    newDecoded = {
      customer_id: data.data.customer_id,
      group_id: data.data.group_id,
      account_id: data.data.account_id,
      admin_id: data.data.admin_id,
      id: data.data.id,
      authentication: data.data.authentication,
      exp: data.exp,
    };
  } else {
    newDecoded = {
      id: data.data.id,
      customer_id: data.data.customer_id,
      full_name: data.data.full_name,
      mobile: data.data.mobile,
      address: data.data.address,
      description: data.data.description,
      email: data.data.email,
      suite_id: data.data.suite_id,
      account_id: data.data.account_id,
      admin_id: data.data.admin_id,
      created_at: data.data.created_at,
      updated_at: data.data.updated_at,
      exp: data.exp,
      isCustomer: true,
    };
  }
  return newDecoded;
}

import { useNavigate } from "react-router-dom";
import { KTCardBody } from "../../../../../../_metronic/helpers";
import { ListViewProvider } from "../../../../site/sites-list/core/ListViewProvider";
import { QueryRequestProvider } from "../../../../site/sites-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "../../../../site/sites-list/core/QueryResponseProvider";
import { ProcoreMigrateProjects, ProcoreViewProjects } from "./core/_request";
import React, { useEffect } from "react";
import ButtonLoader from "../../../../../components/ButtonLoader";

const ListProjects = () => {
  const navigate = useNavigate();

  const [viewProcoreProjects, setViewProcoreProjects]: any = React.useState([]);
  const [checkedProjects, setCheckedProjects]: any = React.useState([]);
  const [projectsLoading, setProjectsLoading] = React.useState(false);

  const ViewProjectsData = async () => {
    let { data: procoreProjectData } = await ProcoreViewProjects("project");
    setViewProcoreProjects(procoreProjectData);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    projectId: string
  ) => {
    const isChecked = event.target.checked;

    if (!checkedProjects.includes(projectId)) {
      setCheckedProjects([...checkedProjects, projectId]);
    } else if (!isChecked && checkedProjects.includes(projectId)) {
      setCheckedProjects(
        checkedProjects.filter((id: string) => id !== projectId)
      );
    }
  };

  const MigrateProjects = async () => {
    try {
      setProjectsLoading(true);
      if (checkedProjects.length >= 1) {
        await ProcoreMigrateProjects(checkedProjects);
        setCheckedProjects([]);
      }
    } catch (error) {
      console.log(error);
    }
    setProjectsLoading(false);
  };

  useEffect(() => {
    ViewProjectsData();
  }, []);

  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <div className="bg-white">
              <KTCardBody>
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed no-footer gs-9 gy-9 gx-9">
                    <thead>
                      <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Project Name
                          </div>
                        </td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Users
                          </div>
                        </td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Action
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                      {viewProcoreProjects?.map((data: any, index: number) => (
                        <tr key={data?.projectId}>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              {data?.projectName}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              <ul className="list-unstyled">
                                {data?.users.map((projectsUsers: any) => (
                                  <li key={projectsUsers?._id}>
                                    {projectsUsers?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              <div className="form-check form-check-custom form-check-solid px-md-5">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={data.projectId}
                                  defaultChecked={data.isMigrated}
                                  id="flexCheckChecked"
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      data.projectId.toString()
                                    )
                                  }
                                />
                                <label className="form-check-label">
                                  Enable
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            <button
                              className="btn btn-secondary"
                              onClick={() => navigate(-1)}
                            >
                              Back
                            </button>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            <button
                              className="btn btn-primary"
                              onClick={() => MigrateProjects()}
                              disabled={
                                checkedProjects.length >= 1 ? false : true
                              }
                            >
                              {!projectsLoading && (
                                <span className="indicator-label">Save</span>
                              )}
                              {projectsLoading && <ButtonLoader />}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </KTCardBody>
            </div>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};

export default ListProjects;

import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { HardwoodPage } from "./HardwoodPage";

const HardwoodPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Hardwood</PageTitle>
      <HardwoodPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default HardwoodPageWrapper;

import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import ListUsers from "./ListUsers";

const userListBreadcrumbs: Array<PageLink> = [
  {
    title: "Status",
    path: "/procore/status",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ListUsersWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="users-list"
          element={
            <>
              <PageTitle breadcrumbs={userListBreadcrumbs}>Users</PageTitle>
              <ListUsers />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/procore/users-list" />} />
    </Routes>
  );
};

export default ListUsersWrapper;

import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { SpecificationsPage } from "./SpecificationPage";

const SpecificationPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Specification</PageTitle>
      <SpecificationsPage className="mb-5 mb-xl-10" page="default" />
    </>
  );
};

export default SpecificationPageWrapper;

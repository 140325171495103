import axios, { AxiosError } from "axios";
import { MigrateProjects, ViewProjects } from "./_models";
import {
  toast,
  toastConfig,
  ToastOptions,
} from "../../../../../../utils/toastService";

const API_URL = process.env.REACT_APP_PROCORE_API_URL;

export const PROCORE_VIEW_PROJECTS_URL = `${API_URL}/procore/view`;
export const PROCORE_MIGRATE_PROJECTS_URL = `${API_URL}/procore/migrate`;

const procoreHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
};

export function ProcoreViewProjects(type: "project") {
  return axios.post<ViewProjects>(
    PROCORE_VIEW_PROJECTS_URL,
    {
      type,
    },
    {
      headers: procoreHeaders,
    }
  );
}

const ProcoreMigrateProjects = async (project: string[]) => {
  try {
    const data = await axios.post<MigrateProjects>(
      PROCORE_MIGRATE_PROJECTS_URL,
      {
        project,
      },
      {
        headers: procoreHeaders,
      }
    );
    toast.success(data.data.message, toastConfig as ToastOptions);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    toast.error(err.response?.data?.message, toastConfig as ToastOptions);
  }
};

export { ProcoreMigrateProjects };

import { useNavigate } from "react-router-dom";
import { KTCardBody } from "../../../../../../_metronic/helpers";
import { ListViewProvider } from "../../../../site/sites-list/core/ListViewProvider";
import { QueryRequestProvider } from "../../../../site/sites-list/core/QueryRequestProvider";
import { QueryResponseProvider } from "../../../../site/sites-list/core/QueryResponseProvider";
import React, { useEffect } from "react";
import { ProcoreMigrateUsers, ProcoreViewUsers } from "./core/_request";
import ButtonLoader from "../../../../../components/ButtonLoader";

const ListUsers = () => {
  const navigate = useNavigate();

  const [viewProcoreUsers, setViewProcoreUsers]: any = React.useState([]);
  const [checkedUsers, setCheckedUsers]: any = React.useState([]);
  const [usersLoading, setUsersLoading] = React.useState(false);

  const ViewUsersData = async () => {
    let { data: procoreUserData } = await ProcoreViewUsers("user");
    setViewProcoreUsers(procoreUserData);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    userId: string
  ) => {
    const isChecked = event.target.checked;

    if (!checkedUsers.includes(userId)) {
      setCheckedUsers([...checkedUsers, userId]);
    } else if (!isChecked && checkedUsers.includes(userId)) {
      setCheckedUsers(checkedUsers.filter((id: string) => id !== userId));
    }
  };

  const MigrateUsers = async () => {
    try {
      setUsersLoading(true);
      if (checkedUsers.length >= 1) {
        await ProcoreMigrateUsers(checkedUsers);
        setCheckedUsers([]);
      }
    } catch (error) {
      console.log(error);
    }
    setUsersLoading(false);
  };

  useEffect(() => {
    ViewUsersData();
  }, []);

  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <div className="bg-white">
              <KTCardBody>
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed no-footer gs-9 gy-9 gx-9">
                    <thead>
                      <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Users Name
                          </div>
                        </td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Projects
                          </div>
                        </td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            Action
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-bold">
                      {viewProcoreUsers?.map((data: any, index: number) => (
                        <tr key={data?.userId}>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              {data?.userName}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              <ul className="list-unstyled">
                                {data?.projects.map((usersProjects: any) => (
                                  <li key={usersProjects?._id}>
                                    {usersProjects?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                              <div className="form-check form-check-custom form-check-solid px-md-5">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={data.userId}
                                  defaultChecked={data.isMigrated}
                                  id="flexCheckChecked"
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event,
                                      data.userId.toString()
                                    )
                                  }
                                />
                                <label className="form-check-label">
                                  Enable
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            <button
                              className="btn btn-secondary"
                              onClick={() => navigate(-1)}
                            >
                              Back
                            </button>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className="d-flex text-center w-100 align-content-center justify-content-center">
                            <button
                              className="btn btn-primary"
                              onClick={() => MigrateUsers()}
                              disabled={checkedUsers.length >= 1 ? false : true}
                            >
                              {!usersLoading && (
                                <span className="indicator-label">Save</span>
                              )}
                              {usersLoading && <ButtonLoader />}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </KTCardBody>
            </div>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};

export default ListUsers;

export const temporaryLoginDetails = [
  {
    error: false,
    message: "Login successful",
    result: {
      token: "#sd23fsdaf",
      user: {
        full_name: "Yusuf Shaikh",
        email: "yusuf@plainsurf.com",
        password: "admin",
      },
      access: {
        features: {
          dashboard: {
            authorized: true,
            role: 4,
          },
          switch_tab: {
            authorized: true,
            role: 4,
          },
          inventories: {
            authorized: true,
            role: 4,
          },
          site: {
            authorized: true,
            role: 4,
          },
          customer: {
            authorized: true,
            role: 4,
          },
          suite: {
            authorized: true,
            role: 4,
          },
          electrical: {
            authorized: true,
            role: 4,
          },
          plumbing_finishes: {
            authorized: true,
            role: 4,
          },
          tile: {
            authorized: true,
            role: 4,
          },
          hardwood: {
            authorized: true,
            role: 4,
          },
          appliances: {
            authorized: true,
            role: 4,
          },
          countertops: {
            authorized: true,
            role: 4,
          },
          invoice: {
            authorized: true,
            role: 4,
          },
          choose_finishing: {
            authorized: false,
            role: 4,
          },
          list_invoices: {
            authorized: true,
            role: 4,
          },
          view_invoice_others: {
            authorized: true,
            role: 4,
          },
          view_invoice_customer: {
            authorized: false,
            role: 4,
          },
          view_specification_others: {
            authorized: true,
            role: 4,
          },
          view_specification_customer: {
            authorized: false,
            role: 4,
          },
          procore: {
            authorized: true,
            role: 4,
          },
          procore_configuration: {
            authorized: true,
            role: 4,
          },
          procore_status: {
            authorized: true,
            role: 4,
          },
        },
      },
    },
  },
  {
    error: false,
    message: "Login successful",
    result: {
      token: "#sd23fsdaf",
      user: {
        full_name: "Mike G",
        email: "mike@mggdigital.com",
        password: "admin",
      },
      access: {
        features: {
          dashboard: {
            authorized: true,
            role: 4,
          },
          switch_tab: {
            authorized: false,
            role: 4,
          },
          inventories: {
            authorized: true,
            role: 4,
          },
          site: {
            authorized: true,
            role: 4,
          },
          customer: {
            authorized: true,
            role: 4,
          },
          suite: {
            authorized: true,
            role: 4,
          },
          electrical: {
            authorized: true,
            role: 4,
          },
          plumbing_finishes: {
            authorized: true,
            role: 4,
          },
          tile: {
            authorized: true,
            role: 4,
          },
          hardwood: {
            authorized: true,
            role: 4,
          },
          appliances: {
            authorized: true,
            role: 4,
          },
          countertops: {
            authorized: true,
            role: 4,
          },
          invoice: {
            authorized: true,
            role: 4,
          },
          choose_finishing: {
            authorized: false,
            role: 4,
          },
          list_invoices: {
            authorized: true,
            role: 4,
          },
          view_invoice_others: {
            authorized: true,
            role: 4,
          },
          view_invoice_customer: {
            authorized: false,
            role: 4,
          },
          view_specification_others: {
            authorized: true,
            role: 4,
          },
          view_specification_customer: {
            authorized: false,
            role: 4,
          },
          procore: {
            authorized: true,
            role: 4,
          },
          procore_configuration: {
            authorized: true,
            role: 4,
          },
          procore_status: {
            authorized: true,
            role: 4,
          },
        },
      },
    },
  },
  {
    error: false,
    message: "Login successful",
    result: {
      token: "#sd23fsdaf",
      user: {
        full_name: "Sales Rep",
        email: "accountant@mggdigital.com",
        password: "admin",
      },
      access: {
        features: {
          dashboard: {
            authorized: true,
            role: 0,
          },
          switch_tab: {
            authorized: false,
            role: 0,
          },
          inventories: {
            authorized: false,
            role: 0,
          },
          suite: {
            authorized: false,
            role: 0,
          },
          customer: {
            authorized: false,
            role: 0,
          },
          electrical: {
            authorized: false,
            role: 0,
          },
          plumbing_finishes: {
            authorized: false,
            role: 0,
          },
          tile: {
            authorized: false,
            role: 0,
          },
          hardwood: {
            authorized: false,
            role: 0,
          },
          appliances: {
            authorized: false,
            role: 0,
          },
          countertops: {
            authorized: false,
            role: 0,
          },
          invoice: {
            authorized: true,
            role: 0,
          },
          choose_finishing: {
            authorized: false,
            role: 0,
          },
          list_invoices: {
            authorized: true,
            role: 0,
          },
          view_invoice_others: {
            authorized: true,
            role: 0,
          },
          view_invoice_customer: {
            authorized: false,
            role: 0,
          },
          view_specification_others: {
            authorized: true,
            role: 0,
          },
          view_specification_customer: {
            authorized: false,
            role: 0,
          },
          procore: {
            authorized: true,
            role: 0,
          },
          procore_configuration: {
            authorized: true,
            role: 0,
          },
          procore_status: {
            authorized: true,
            role: 0,
          },
        },
      },
    },
  },
  {
    error: false,
    message: "Login successful",
    result: {
      token: "#sd23fsdaf",
      user: {
        full_name: "Customer",
        email: "customer@mggdigital.com",
        password: "admin",
      },
      access: {
        features: {
          dashboard: {
            authorized: false,
            role: 0,
          },
          switch_tab: {
            authorized: false,
            role: 0,
          },
          inventories: {
            authorized: false,
            role: 0,
          },
          suite: {
            authorized: false,
            role: 0,
          },
          customer: {
            authorized: false,
            role: 0,
          },
          electrical: {
            authorized: false,
            role: 0,
          },
          plumbing_finishes: {
            authorized: false,
            role: 0,
          },
          tile: {
            authorized: false,
            role: 0,
          },
          hardwood: {
            authorized: false,
            role: 0,
          },
          appliances: {
            authorized: false,
            role: 0,
          },
          countertops: {
            authorized: false,
            role: 0,
          },
          invoice: {
            authorized: true,
            role: 0,
          },
          choose_finishing: {
            authorized: true,
            role: 0,
          },
          list_invoices: {
            authorized: false,
            role: 0,
          },
          view_invoice_others: {
            authorized: false,
            role: 0,
          },
          view_invoice_customer: {
            authorized: true,
            role: 0,
          },
          view_specification_others: {
            authorized: false,
            role: 0,
          },
          view_specification_customer: {
            authorized: true,
            role: 0,
          },
          procore: {
            authorized: false,
            role: 0,
          },
          procore_configuration: {
            authorized: false,
            role: 0,
          },
          procore_status: {
            authorized: false,
            role: 0,
          },
        },
      },
    },
  },
];

export const dummyLogin = [
  {
    email: "yusuf@plainsurf.com",
    password: "admin",
  },
  {
    email: "mike@mggdigital.com",
    password: "admin",
  },
  {
    email: "accountant@mggdigital.com",
    password: "admin",
  },
  {
    email: "customer@mggdigital.com",
    password: "admin",
  },
];

export const counterTops = [
  {
    _id: "62533b72167c66a471d01de4",
    area: "Kitchen",
    option_selected: [
      {
        option: "Standard",
        sf_cost: 0,
      },
      {
        option: "Upgrade_A_Counter",
        sf_cost: 0,
      },
      {
        option: "Upgrade_B_Counter",
        sf_cost: 0,
      },
    ],
    counter_selection: "Cesear Stone_Calcutta_Novo",
    edge_detail: "Eased Edge",
  },
  {
    _id: "62533b72167c66a471d01de5",
    area: "Master Bath",
    option_selected: [
      {
        option: "Standard",
        sf_cost: 20,
      },
      {
        option: "Upgrade_A_Counter",
        sf_cost: 20,
      },
      {
        option: "Upgrade_B_Counter",
        sf_cost: 20,
      },
    ],
    counter_selection: "Cesear Stone_Calcutta_Novo",
    edge_detail: "Bullnose",
  },
  {
    _id: "62533b72167c66a471d01de6",
    area: "Guest Bath",
    option_selected: [
      {
        option: "Standard",
        sf_cost: 20,
      },
      {
        option: "Upgrade_A_Counter",
        sf_cost: 20,
      },
      {
        option: "Upgrade_B_Counter",
        sf_cost: 20,
      },
    ],
    counter_selection: "Cesear Stone_Calcutta_Novo",
    edge_detail: "Waterfall",
  },
  {
    _id: "62533b72167c66a471d01de7",
    area: "Powder Room",
    option_selected: [
      {
        option: "Standard",
        sf_cost: 20,
      },
      {
        option: "Upgrade_A_Counter",
        sf_cost: 20,
      },
      {
        option: "Upgrade_B_Counter",
        sf_cost: 20,
      },
    ],
    counter_selection: "Cesear Stone_Calcutta_Novo",
    edge_detail: "Eased Edge",
  },
  {
    _id: "62533b72167c66a471d01de8",
    area: "Laundry",
    option_selected: [
      {
        option: "Standard",
        sf_cost: 20,
      },
      {
        option: "Upgrade_A_Counter",
        sf_cost: 20,
      },
      {
        option: "Upgrade_B_Counter",
        sf_cost: 20,
      },
    ],
    counter_selection: "Cesear Stone_Calcutta_Novo",
    edge_detail: "Eased Edge",
  },
];

export const sites = {
  data: [
    {
      _id: "633936a095ae160c46fcb6e0",
      name: "Plainsurf_Site_2",
      address: "Nagpur, India",
      area: "West Zone",
      city: "",
      state: "",
      country: "",
      description: "This is Plainsurf Test Site 2",
      number_of_condos: 2,
      site_image_name: "site-image.png",
      created_at: "2022-10-02 12:28:40",
      updated_at: "2022-10-02 12:28:40",
      account_id: "1",
      admin_id: "6265a4b3eaefac59a77f4207",
    },
  ],
};

export const suites = {
  data: [
    {
      id: 1,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 1,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 1",
    },
    {
      id: 2,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 2,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 2",
    },
    {
      id: 3,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 3,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 3",
    },
    {
      id: 4,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 4,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 4",
    },
    {
      id: 5,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 5,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 5",
    },
    {
      id: 6,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 6,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 6",
    },
    {
      id: 7,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 7,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 7",
    },
    {
      id: 8,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 8,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 8",
    },
    {
      id: 9,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 9,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 9",
    },
    {
      id: 10,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 10,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 10",
    },
    {
      id: 11,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 11,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 11",
    },
    {
      id: 12,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 12,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 12",
    },
    {
      id: 13,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 13,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 13",
    },
    {
      id: 14,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 14,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 14",
    },
    {
      id: 15,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 15,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 15",
    },
    {
      id: 16,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 16,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 16",
    },
    {
      id: 17,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 17,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 17",
    },
    {
      id: 18,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 18,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 18",
    },
    {
      id: 19,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 19,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 19",
    },
    {
      id: 20,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 20,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 20",
    },
    {
      id: 21,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 21,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 21",
    },
    {
      id: 22,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 22,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 22",
    },
    {
      id: 23,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 23,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 23",
    },
    {
      id: 24,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 24,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 24",
    },
    {
      id: 25,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 25,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 25",
    },
    {
      id: 26,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 26,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 26",
    },
    {
      id: 27,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 27,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 27",
    },
    {
      id: 28,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 28,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 28",
    },
    {
      id: 29,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 29,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 29",
    },
    {
      id: 30,
      site: "test site",
      creator: "admin",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
      suite: 30,
      sales_rep: "accountant@mggdigital.com",
      description: "Test suite 30",
    },
  ],
};
export const electricals = [
  {
    id: 1,
    type: "Duplex outlet",
    unit_cost: 250,
  },
  {
    id: 2,
    type: "Duplex outlet GFCI",
    unit_cost: 280,
  },
  {
    id: 3,
    type: "Outside Balcony Outlet",
    unit_cost: 300,
  },
  {
    id: 4,
    type: "Split Circuit Outlet ( counters & islands)",
    unit_cost: 280,
  },
  {
    id: 5,
    type: "Microwave Outlet",
    unit_cost: 300,
  },
  {
    id: 6,
    type: "Rough-in for LTG box ( no fixture)",
    unit_cost: 250,
  },
  {
    id: 67,
    type: "Rough-in for LTG Box & Switch (no fixture)",
    unit_cost: 430,
  },
  {
    id: 7,
    type: "Single Pole switch",
    unit_cost: 250,
  },
  {
    id: 8,
    type: "3way switch",
    unit_cost: 350,
  },
  {
    id: 9,
    type: "4way switch",
    unit_cost: 600,
  },
  {
    id: 10,
    type: "single pole LED dimmer",
    unit_cost: 350,
  },
  {
    id: 11,
    type: "3way LED dimmer",
    unit_cost: 500,
  },
  {
    id: 12,
    type: "floor mounted outlet",
    unit_cost: 700,
  },
  {
    id: 13,
    type: "jacuzzi connection",
    unit_cost: 800,
  },
  {
    id: 14,
    type: '4" low volt potlight (fixture included)',
    unit_cost: 300,
  },
  {
    id: 15,
    type: '4" line voltage potlight ',
    unit_cost: 240,
  },
  {
    id: 16,
    type: '4" LED potlight ( fixture included)',
    unit_cost: 460,
  },
  {
    id: 17,
    type: '6" LED potlight ( fixture included)',
    unit_cost: 520,
  },
  {
    id: 18,
    type: "Undercabinet - this was included",
    unit_cost: 0,
  },
  {
    id: 19,
    type: "Supply & Install puck LTG ( 3 per connection)",
    unit_cost: 700,
  },
  {
    id: 20,
    type: "Speaker pre-wire (per speaker location)",
    unit_cost: 400,
  },
  {
    id: 21,
    type: "Wine fridge connection (wall)",
    unit_cost: 300,
  },
  {
    id: 22,
    type: "Wine fridge connection ( island)",
    unit_cost: 350,
  },
  {
    id: 23,
    type: "Wall oven connection",
    unit_cost: 700,
  },
  {
    id: 24,
    type: "Electric Cooktop Connection",
    unit_cost: 700,
  },
  {
    id: 25,
    type: "Electric Cooktop Connection (island)",
    unit_cost: 800,
  },
  {
    id: 26,
    type: "Gas cooktop connection",
    unit_cost: 500,
  },
  {
    id: 27,
    type: "Gas cooktop connection (island)",
    unit_cost: 600,
  },
  {
    id: 28,
    type: "Kitchen Hood connection",
    unit_cost: 350,
  },
  {
    id: 29,
    type: "Data/Phone/TV outlet",
    unit_cost: 300,
  },
  {
    id: 30,
    type: "Ceiling Mounted Fixture",
    unit_cost: 200,
    cost: 0,
  },
];

export const tilesTable = [
  {
    id: 1,
    area: "Kitchen backspash",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 2,
    area: "Master Bath Floor",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 3,
    area: "Master Bath Walls",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 4,
    area: "Master Shower Floor",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 5,
    area: "Master Shower Wall",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 6,
    area: "Guest Bath Floor",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 7,
    area: "Guest Bath Wall",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 8,
    area: "Guest Shower Floor",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 9,
    area: "Guest Shower Wall",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
  {
    id: 10,
    area: "Powder Room Floor",
    option_selected: [
      {
        option: "standard",
        tile_selection: [
          "Glazed Wall_Gatineau Glossy 4x16_White Gloss",
          "Glazed Wall_Gatineau Matte 4x16_Grey",
          "Porcelain_Architectura Matte 12x24_Beige",
          "Porcelain_Architectura Matte 12x24_Grafito (Dark Grey)",
          "Porcelain_Clay 12x24_Black",
          "Porcelain_Clay 12x24_Grey",
          "Porcelain_Clay 12x24_Noce (Brown)",
          "Porcelain_Oikos 12x24_Antracite (Charcoal)",
          "Porcelain_Oikos 12x24_Avorio (Ivory)",
          "Porcelain_Oikos 12x24_Grigio (Grey)",
          "Porcelain_Regal Matte 12x24_Charcoal Black",
          "Porcelain_Regal Matte 12x24_Dark Grey",
          "Porcelain_Regal Matte 12x24_Grey",
          "Porcelain_Regal Matte 12x24_Royale Crema",
          "Porcelain_Regal Matte 12x24_Shell White",
          "Porcelain_Regal Matte 12x24_Taupe",
          "Porcelain_Regal Polished 12x24_Charcoal Black",
          "Porcelain_Regal Polished 12x24_Dark Grey",
          "Porcelain_Regal Polished 12x24_Grey",
          "Porcelain_Regal Polished 12x24_Royale Crema",
          "Porcelain_Regal Polished 12x24_Shell White",
          "Porcelain_Regal Polished 12x24_Taupe",
          "Mosaic_Regal Matte 12x12_ Charcoal Black",
          "Mosaic_Regal Matte 12x12_ Dark Grey",
          "Mosaic_Regal Matte 12x12_ Grey",
          "Mosaic_Regal Matte 12x12_ Shell White",
          "Porcelain_Unicolour Matte 12x24_Chocolate",
          "Porcelain_Unicolour Matte 12x24_Dark Grey",
          "Porcelain_Unicolour Matte 12x24_Extra White",
          "Porcelain_Unicolour Matte 12x24_Ivory",
          "Porcelain_Unicolour Matte 12x24_Light Grey",
          "Porcelain_Unicolour Matte 12x24_Taupe",
          "Porcelain_Unicolour Matte 12x24_Super Black",
          "Porcelain_Unicolour Matte 12x24_Super White",
          "Porcelain_Unicolour Polished 12x24_Chocolate",
          "Porcelain_Unicolour Polished 12x24_Dark Grey",
          "Porcelain_Unicolour Polished 12x24_Extra White",
          "Porcelain_Unicolour Polished 12x24_Ivory",
          "Porcelain_Unicolour Polished 12x24_Light Grey",
          "Porcelain_Unicolour Polished 12x24_Taupe",
          "Porcelain_Unicolour Polished 12x24_Super Black",
          "Porcelain_Unicolour Polished 12x24_Super White",
          "Mosaic_Unicolour Matte 12x12_Dark Grey",
          "Mosaic_Unicolour Matte 12x12_Extra White",
          "Mosaic_Unicolour Matte 12x12_Light Grey",
          "Porcelain_Yura New 12x24_Foggy (Light Grey)",
          "Porcelain_Yura New 12x24_Ivory",
          "Porcelain_Yura New 12x24_Snow",
          "Marble Tile Polished 12x12_Bianco Carrara",
          "Marble Tile Polished 12x12_Botticino F",
          "Marble Tile Polished 12x12_Fossil Cream",
          "Marble Tile Polished 12x12_Gala Grey",
          "Marble Tile Polished 12x24_Silver Shadow",
          "Marble Tile Polished 16x24_Silver Shadow",
        ],
        sf_cost: 0.0,
      },
      {
        option: "upgradeA",
        tile_selection: [
          "Porcelain_Geotech Matte 16x32_Black",
          "Porcelain_Geotech Matte 16x32_White",
          "Porcelain_Kauri Undulated/Honed 12x24_Beige",
          "Porcelain_Kauri Undulated/Honed 12x24_Grey",
          "Porcelain_Kauri Undulated/Honed 12x24_White",
          "Marble Tile Polished 12x12_Bianco Carrara C Extra",
          "Marble Tile Polished 12x12_Crema Marfil Select Grade",
          "Marble Tile Polished 12x24_Bianco Carrara C Extra",
          "Travertine Tile Honed 16x24_Cappuccino Light",
          "",
        ],
        sf_cost: 25.0,
      },
      {
        option: "upgradeB",
        tile_selection: [
          "Marble Tile Polished 12x24_Calacatta",
          "Travertine Tile Polished 12x24_Ocean Black",
          "Travertine Tile Polished 12x24_Titanium",
          "Travertine Tile Honed 12x24_Dorato Vein Cut",
        ],
        sf_cost: 50.0,
      },
    ],
  },
];

export const plumbingFinishes = [
  {
    _id: "6259d9eff852f8e1d13930ce",
    area: "Kitchen",
    fixture: [
      {
        fixture_name: "faucet",
        client_fixture_selection: [
          {
            selection: "Standard -AM STD Reliant",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - Grohe Minta",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "sink",
        client_fixture_selection: [
          {
            selection: "Standard - Canadian",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - Blanco Double",
            unit_cost: 300,
          },
          {
            selection: "Upgrade - Blanco Square D",
            unit_cost: 750,
          },
          {
            selection: "Upgrade Blanco 1.5",
            unit_cost: 250,
          },
        ],
      },
    ],
  },

  {
    _id: "6259db02f852f8e1d13930cf",
    area: "Master Bath",
    fixture: [
      {
        fixture_name: "faucet",
        client_fixture_selection: [
          {
            selection: "Standard - Americal Standard Monoblock Faucet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade- American Standard Fern Faucet",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "sink",
        client_fixture_selection: [
          {
            selection: "Standard - Studio Undercounter Sink 21 1/4 x 15 1/4",
            unit_cost: 0,
          },
          {
            selection:
              "Upgrade - Studio Large Undercounter Sink  23 5/8 x 16 5/8",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "wc",
        client_fixture_selection: [
          {
            selection:
              "Standard - American Standard FloWise Dual Flush Elongated Toilet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - TOTO Aquia II Dual Flush Toilet",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "tub",
        client_fixture_selection: [
          {
            selection: "Standard - Studio 5x32 Drop-In Bathing Pool",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - Studio 5x32 EverClean Air Bath",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "shower",
        client_fixture_selection: [
          {
            selection: "Standard - Americal Standard Boulevard Shower",
            unit_cost: 0,
          },
          {
            selection:
              "Upgrade - American Standard Boulevard with Telephone Shower",
            unit_cost: 500,
          },
        ],
      },
    ],
  },

  {
    _id: "6259db9bf852f8e1d13930d0",
    area: "Guest Bath",
    fixture: [
      {
        fixture_name: "faucet",
        client_fixture_selection: [
          {
            selection: "Standard - Americal Standard Monoblock Faucet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade- American Standard Fern Faucet",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "sink",
        client_fixture_selection: [
          {
            selection: "Standard - Studio Undercounter Sink 21 1/4 x 15 1/4",
            unit_cost: 0,
          },
          {
            selection:
              "Upgrade - Studio Large Undercounter Sink  23 5/8 x 16 5/8",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "wc",
        client_fixture_selection: [
          {
            selection:
              "Standard - American Standard FloWise Dual Flush Elongated Toilet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - TOTO Aquia II Dual Flush Toilet",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "tub",
        client_fixture_selection: [
          {
            selection: "Standard - Studio 5x32 Drop-In Bathing Pool",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - Studio 5x32 EverClean Air Bath",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "shower",
        client_fixture_selection: [
          {
            selection: "Standard - Americal Standard Boulevard Shower",
            unit_cost: 0,
          },
          {
            selection:
              "Upgrade - American Standard Boulevard with Telephone Shower",
            unit_cost: 500,
          },
        ],
      },
    ],
  },

  {
    _id: "6259dbfdf852f8e1d13930d1",
    area: "Powder Room",
    fixture: [
      {
        fixture_name: "faucet",
        client_fixture_selection: [
          {
            selection: "Standard - Americal Standard Monoblock Faucet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade- American Standard Fern Faucet",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "sink",
        client_fixture_selection: [
          {
            selection: "Standard - Studio Undercounter Sink 21 1/4 x 15 1/4",
            unit_cost: 0,
          },
          {
            selection:
              "Upgrade - Studio Large Undercounter Sink  23 5/8 x 16 5/8",
            unit_cost: 500,
          },
        ],
      },
      {
        fixture_name: "wc",
        client_fixture_selection: [
          {
            selection:
              "Standard - American Standard FloWise Dual Flush Elongated Toilet",
            unit_cost: 0,
          },
          {
            selection: "Upgrade - TOTO Aquia II Dual Flush Toilet",
            unit_cost: 500,
          },
        ],
      },
    ],
  },
];

export const hardwoods = [
  {
    _id: "62533a4f167c66a471d01de3",
    area: "All areas except bathroom",
    option_selected: [
      {
        option: "standard_wood",
        wood_selection: [
          "torlys_artisan_oak_chestnut",
          "torlys_artisan_ maple",
          "torlys_artisan_cherry",
        ],
        sf_cost: 0,
      },
      {
        option: "upgradeA_wood",
        wood_selection: [
          "torlys_heritage_walnut",
          "torlys_heritage_redoak",
          "torlys_heritage_whiteash",
        ],
        sf_cost: 5,
      },
      {
        option: "upgradeB_wood",
        wood_selection: [
          "torlys_everest_alpinewhite",
          "torlys_everest_truffle",
          "torlys_everest_chocolate",
        ],
        sf_cost: 10,
      },
    ],
    direction: ["North-South", "East-West"],
  },
];

export const appliances = [
  {
    _id: "6259d17af852f8e1d13930cd",
    standard_option: {
      package_type: "Standard Option Appliances",
      packages: [
        {
          miele_standard_package: "Oven",
          model_description: "30inch Electric (Model #??)",
        },
        {
          miele_standard_package: "Fridge",
          model_description: "24inch Stainless Oven-under",
        },
        {
          miele_standard_package: "Cooktop",
          model_description: "30inch Gas Cooktop stainless",
        },
        {
          miele_standard_package: "Microwave",
          model_description: "30inch Over-The Range Stainless",
        },
        {
          miele_standard_package: "Dishwasher",
          model_description: "24inch Deep tub stainless",
        },
      ],
    },
    upgrade_option: [
      {
        package_type: "Miele Premium Package",
        packages: [
          {
            appliances: "Oven",
            model_description: "36inch Stove (Model#)",
          },
          {
            appliances: "Fridge",
            model_description: "24inch fridge",
          },
          {
            appliances: "Cooktop",
            model_description: "36inch cooktop",
          },
          {
            appliances: "Microwave",
            model_description: "36inch Micro Stainless",
          },
          {
            appliances: "Dishwasher",
            model_description: "24inch Deep Tub Stainless",
          },
        ],
        cost: 5000,
      },
      {
        package_type: "Miele Platnum Package",
        packages: [
          {
            appliances: "Oven",
            model_description: "36inch Wall Oven",
          },
          {
            appliances: "Fridge",
            model_description: "36inch Fridge",
          },
          {
            appliances: "Cooktop",
            model_description: "36inch stainless gas",
          },
          {
            appliances: "Microwave",
            model_description: "36inch Micro Stainless",
          },
          {
            appliances: "Dishwasher",
            model_description: "24inch Deep Tub Stainless",
          },
        ],
        cost: 10000,
      },
      {
        package_type: "Miele Chef Package",
        packages: [
          {
            appliances: "Oven",
            model_description: "48inch electric oven/gas Range",
          },
          {
            appliances: "Fridge",
            model_description: "36inch Stainless fridge with Ice",
          },
          {
            appliances: "Cooktop",
            model_description: "incl with oven",
          },
          {
            appliances: "Microwave",
            model_description: "36inch Stainless",
          },
          {
            appliances: "Dishwasher",
            model_description: "24inch deep Tub stainless plain front",
          },
        ],
        cost: 15000,
      },
    ],
  },
];

export const invoices = {
  data: [
    {
      _id: "625486ae604a5edcc37b26ac",
      suite_id: 101,
      customer_id: 102,
      invoice_id: 1001,
      customer_name: "Test customer",
      creator: "accountant@mggdigital.com",
      invoice_created_date: "10 Nov 2022, 9:23 pm",
      invoice_modified_date: "10 Nov 2022, 9:23 pm",
      bill_to: "Mr & Mrs Jones",
      address: "123 Silverbirch Toronto, On, M4E 3H3",
      phone: 4166980101,
      email: "jones@hotmail.com",
      inventories: [
        {
          description: "Electrical",
          option_name: "Upgrades per customer",
          units: 0.0,
          cost: 0.0,
          total_cost: 1250.0,
        },
        {
          description: "Appliances",
          option_name: "Standard Option",
          units: 0.0,
          cost: 0.0,
          total_cost: 0.0,
        },
        {
          description: "Hardwood",
          option_name: "torlys_heritage_redoak",
          units: 0.0,
          cost: 0.0,
          total_cost: 5388.15,
        },
        {
          description: "Tile",
          option_name: "Upgrade backsplash, Guest Bath, Master Bath",
          units: 0.0,
          cost: 0.0,
          total_cost: 3259.0,
        },
        {
          description: "Plumbing",
          option_name: "Upgrade Kitchen Sink, WC's",
          units: 0.0,
          cost: 0.0,
          total_cost: 2000.0,
        },
        {
          description: "CounterTops",
          option_name: "Upgrade kitchen, master, guest, Powder, Laundry",
          units: 0.0,
          cost: 0.0,
          total_cost: 640.0,
        },
        {
          description: "Custom Items",
          option_name: "Custom Fireplace",
          units: 0.0,
          cost: 0.0,
          total_cost: 5000.0,
        },
      ],
      subtotal: 17537.15,
      procession_fee: 4384.29,
      invoice_subtotal: 21921.44,
      tax_rate: 13.0,
      hst: 2849.79,
      other_fees: 0.0,
      total: 24771.22,
    },
  ],
};

export const customers = {
  data: [
    {
      id: 1,
      _id: "6265a6d2eaefac59a77f420b",
      address: "Test site",
      full_name: "Accountant Mike",
      creator: "accountant@mggdigital.com",
      mobile: 8778899554,
      description: "Test desc",
      createdAt: "10 Nov 2022, 9:23 pm",
      updatedAt: "10 Nov 2022, 9:23 pm",
    },
  ],
};

export const procoreStatus = [
  {
    id: 1,
    _id: "6265a6d2eaefac59a77f410b",
    entity: "Company",
    name: "PlainSurf",
    url: "",
    updatedDate: "4 October 2022",
    error: "No Error",
    status: "Success",
    action: "Sync",
  },
  {
    id: 2,
    _id: "6265a6d2eaefac59a77f420b",
    entity: "Projects",
    name: "Click here to view projects",
    url: "/procore/projects-list",
    updatedDate: "18 May 2022",
    error: "Error",
    status: "Failed",
    action: "Sync",
  },
  {
    id: 3,
    _id: "6265a6d2eaefac59a77f430b",
    entity: "Users",
    name: "Click here to view users",
    url: "/procore/users-list",
    updatedDate: "31 December 2022",
    error: "No Error",
    status: "Success",
    action: "Sync",
  },
];

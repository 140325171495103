/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { plumbing_finishes } from "../../modules/auth/core/_requests";
import {
  getPlumbingFinishes,
  setPlumbingFinishes,
} from "../../modules/auth/core/InventoryHelpers";
import { KTSVG } from "../../../_metronic/helpers";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
};

let total: number = 0;

const PlumbingFinishesPage: React.FC<Props> = ({
  className,
  page,
  invoiceType,
}) => {
  let [plumbingFinishes, setPlumbingFinishesData]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);

  const fetchPlumbingFinishes = async () => {
    let plumbingFinishes = await plumbing_finishes();
    setPlumbingFinishes(plumbingFinishes.data.result);
    let localPlumbingFinishes = getPlumbingFinishes();
    setPlumbingFinishesData(localPlumbingFinishes);
  };

  useEffect(() => {
    let localPlumbingFinishes = getPlumbingFinishes();
    if (invoiceTypeMode === "edit") {
      let localStoragePlumbingFinishes: any =
        localStorage.getItem("plumbing_finishes");
      let tempSavedPlumbingFinishes: any = JSON.parse(
        localStoragePlumbingFinishes
      );
      total = tempSavedPlumbingFinishes?.total;
      setPlumbingFinishesData(tempSavedPlumbingFinishes?.data);
    } else {
      if (!localPlumbingFinishes) {
        fetchPlumbingFinishes();
      } else {
        setPlumbingFinishesData(localPlumbingFinishes);
      }
    }
  }, []); // eslint-disable-line

  const handleChange = (event: string, index: number) => {
    let clone = [...plumbingFinishes];
    let obj = clone[index];
    let tempTotal: number = 0;

    for (let i = 0; i < obj.fixture.length; i++) {
      for (let j = 0; j < obj.fixture[i].client_fixture_selection.length; j++) {
        if (obj.fixture[i].client_fixture_selection[j].selection === event) {
          obj.fixture[i].unit_cost =
            obj.fixture[i].client_fixture_selection[j].unit_cost;
          // obj.fixture[i].unit = undefined;
          obj.fixture[i].cost = 0;
          obj.fixture[i].cost = obj.fixture[i].unit_cost * obj.fixture[i].unit;
          obj.fixture[i].client_fixture_selected =
            obj.fixture[i].client_fixture_selection[j].selection;

          obj.fixture[i].client_fixture_selection[j].selected = true;
        } else {
          obj.fixture[i].cost = obj.fixture[i].unit_cost * obj.fixture[i].unit;
          // obj.fixture[i].client_fixture_selected = obj.fixture[i].client_fixture_selection[j].selection;

          obj.fixture[i].client_fixture_selection[j].selected = false;
        }
      }
    }
    clone[index] = obj;
    setPlumbingFinishesData([...clone]);

    // let selectedItem: any[] = [];

    // for (let i = 0; i < plumbingFinishes.length; i++) {
    //   selectedItem.push(plumbingFinishes[i])
    //   let localstorageData = {
    //     total: total,
    //     data: selectedItem
    //   }
    //   localStorage.setItem('plumbing_finishes', JSON.stringify(localstorageData));
    // }

    let localstorageData = {
      total: total,
      data: plumbingFinishes,
    };
    localStorage.setItem("plumbing_finishes", JSON.stringify(localstorageData));

    let localStoragePlumbingFinishes: any =
      localStorage.getItem("plumbing_finishes");
    let tempSavedPlumbingFinishes: any = JSON.parse(
      localStoragePlumbingFinishes
    );
    let savedPlumbingFinishes: any = tempSavedPlumbingFinishes.data;
    for (let i = 0; i < savedPlumbingFinishes.length; i++) {
      for (let j = 0; j < savedPlumbingFinishes[i].fixture.length; j++) {
        if (
          savedPlumbingFinishes[i].fixture[j].cost &&
          savedPlumbingFinishes[i].fixture[j].cost > 0
        ) {
          if (savedPlumbingFinishes[i].fixture[j]?.cost) {
            tempTotal = tempTotal + savedPlumbingFinishes[i].fixture[j]?.cost;
          }
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedPlumbingFinishes,
      };
      localStorage.setItem(
        "plumbing_finishes",
        JSON.stringify(localstorageData)
      );
    }
  };

  const calculateCost = (event: string, index: number, fixture: any) => {
    const unit: number = Number(event);
    let clone = [...plumbingFinishes];
    let obj = clone[index];
    let tempTotal: number = 0;

    for (let i = 0; i < obj.fixture.length; i++) {
      for (let j = 0; j < obj.fixture[i].client_fixture_selection.length; j++) {
        if (obj.fixture[i].fixture_name === fixture.fixture_name) {
          obj.fixture[i].unit = unit;
          obj.fixture[i].cost = obj.fixture[i].unit_cost * unit;
        }
      }
    }
    clone[index] = obj;
    setPlumbingFinishesData([...clone]);

    let selectedItem = [];

    for (let i = 0; i < plumbingFinishes.length; i++) {
      selectedItem.push(plumbingFinishes[i]);
      let localstorageData = {
        total: total,
        data: selectedItem,
      };
      localStorage.setItem(
        "plumbing_finishes",
        JSON.stringify(localstorageData)
      );
    }

    let localStoragePlumbingFinishes: any =
      localStorage.getItem("plumbing_finishes");
    let tempSavedPlumbingFinishes: any = JSON.parse(
      localStoragePlumbingFinishes
    );
    let savedPlumbingFinishes: any = tempSavedPlumbingFinishes.data;
    for (let i = 0; i < savedPlumbingFinishes.length; i++) {
      for (let j = 0; j < savedPlumbingFinishes[i].fixture.length; j++) {
        if (
          savedPlumbingFinishes[i].fixture[j].cost &&
          savedPlumbingFinishes[i].fixture[j].cost > 0
        ) {
          tempTotal = tempTotal + savedPlumbingFinishes[i].fixture[j]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedPlumbingFinishes,
      };
      localStorage.setItem(
        "plumbing_finishes",
        JSON.stringify(localstorageData)
      );
    }
    // if (unit === 0) {
    //   const index = savedPlumbingFinishes.findIndex((saved: any) => saved.id === fixture.id);
    //   if (index > -1) {
    //     savedPlumbingFinishes.splice(index, 1);
    //   }
    //   let localstorageData = {
    //     total: total,
    //     data: savedPlumbingFinishes
    //   }

    //   localStorage.setItem('plumbing_finishes', JSON.stringify(localstorageData));
    // }
  };

  // Search Function : Begins

  const [query, setQuery] = useState("");

  // Search Function : Ends

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Plumbing Finishes
            </span>
          </h3>
        </div>
      )}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin:: Table Search Box */}
        {page === "invoice" ? null : (
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1 pt-10 pb-10">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Search plumbing finishes"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        )}
        {/* end:: Table Search Box */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-160px">Area</th>
                <th className="min-w-120px">Fixture</th>
                <th className="min-w-60px" style={{ width: "25%" }}>
                  Client fixture selection
                </th>
                <th className="min-w-180px">Unit cost</th>

                {page === "invoice" && (
                  <>
                    <th className="min-w-120px">Units</th>
                    <th className="min-w-120px">Cost</th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {plumbingFinishes?.length &&
                plumbingFinishes
                  ?.filter((data: { area: string }) =>
                    data?.area.toLowerCase().includes(query)
                  )
                  .map((data: any, index: number) => (
                    <tr key={index} style={{ lineHeight: "50px" }}>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.area}
                        </a>
                      </td>
                      <td>
                        {data?.fixture.map((fixture: any, index: number) => (
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary fs-6"
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "50px",
                            }}
                          >
                            {fixture.fixture_name}
                          </a>
                        ))}
                      </td>
                      <td className="text-center">
                        {data?.fixture.map(
                          (fixture: any, fixture_index: number) => (
                            <select
                              className="form-select form-select-solid"
                              data-kt-select2="true"
                              data-placeholder="Select option"
                              data-allow-clear="true"
                              defaultValue={
                                fixture?.client_fixture_selected ||
                                fixture?.client_fixture_selection[0]?.selection
                              }
                              style={{ marginBottom: "10px" }}
                              onChange={(e) =>
                                handleChange(e.target.value, index)
                              }
                              key={fixture_index}
                            >
                              {fixture.client_fixture_selection.map(
                                (selection: any, index: number) => (
                                  <option
                                    key={index}
                                    value={selection.selection}
                                  >
                                    {selection.selection}
                                  </option>
                                )
                              )}
                            </select>
                          )
                        )}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          lineHeight: "50px",
                        }}
                      >
                        {data?.fixture.map((fixture: any, index: number) => (
                          <a
                            href="#"
                            key={index}
                            className="text-dark fw-bolder text-hover-primary fs-6"
                          >
                            {/* {fixture.client_fixture_selection[1].unit_cost} */}
                            $
                            {fixture.unit_cost ||
                              fixture.client_fixture_selection[0].unit_cost}
                          </a>
                        ))}
                      </td>

                      {page === "invoice" && (
                        <>
                          <td className="text-center">
                            {data?.fixture.map(
                              (fixture: any, fixture_index: number) => (
                                <input
                                  type="number"
                                  min={0}
                                  style={{ marginBottom: "5px" }}
                                  // data-kt-user-table-filter='search'
                                  className="form-control form-control-solid w-150px ps-14"
                                  placeholder="Unit"
                                  key={fixture_index}
                                  value={fixture?.unit || undefined}
                                  onChange={(e) =>
                                    calculateCost(
                                      e.target.value,
                                      index,
                                      fixture
                                    )
                                  }
                                />
                              )
                            )}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              lineHeight: "50px",
                            }}
                          >
                            {data?.fixture.map(
                              (fixture: any, index: number) => (
                                <a
                                  href="#"
                                  key={index}
                                  className="text-dark fw-bolder text-hover-primary fs-6"
                                >
                                  {/* {fixture.client_fixture_selection[1].unit_cost} */}
                                  $
                                  {fixture?.cost
                                    ? fixture?.cost?.toFixed(2)
                                    : 0}
                                </a>
                              )
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}

              {page === "invoice" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 600 }} className="text-center">
                      Total
                    </td>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                        style={{ paddingLeft: "2.5%" }}
                      >
                        ${total?.toFixed(2) || 0}
                      </a>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { PlumbingFinishesPage };

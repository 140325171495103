/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { appliance } from "../../modules/auth/core/_requests";
import {
  getAppliances,
  setAppliances,
} from "../../modules/auth/core/InventoryHelpers";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
};

const packageOptions = [
  { value: "", text: "Select Package" },
  { value: "Standard Options", text: "Standard Options" },
  { value: "Miele Premium Package", text: "Miele Premium Package" },
  { value: "Miele Platnum Package", text: "Miele Platnum Package" },
  { value: "Miele Chef Package", text: "Miele Chef Package" },
];

const AppliancesPage: React.FC<Props> = ({ className, page, invoiceType }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Appliance, setApplianceData]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);
  const [selected, setSelected] = useState(packageOptions[0].value);

  const fetchAppliancesData = async () => {
    let AppliancesData = await appliance();
    setAppliances(AppliancesData.data.result);
    let localAppliances = getAppliances();
    setApplianceData(localAppliances);
  };

  useEffect(() => {
    let localAppliances = getAppliances();
    if (invoiceTypeMode === "edit") {
      let localStorageAppliances: any = localStorage.getItem("appliances");
      let tempSavedAppliances: any = JSON.parse(localStorageAppliances);
      setSelected(tempSavedAppliances?.data?.package_type);
    }
    if (!localAppliances) {
      fetchAppliancesData();
    } else {
      setApplianceData(localAppliances);
    }
  }, []); // eslint-disable-line

  const handleOptionSelect = (event: any) => {
    setSelected(event.target.value);
    if (event.target.value === "Standard Options") {
      let localstorageData = {
        total: 0,
        data: Appliance[0].standard_option,
      };
      localStorage.setItem("appliances", JSON.stringify(localstorageData));
    }
    for (let i = 0; i < Appliance[0].upgrade_option.length; i++) {
      if (Appliance[0].upgrade_option[i].package_type === event.target.value) {
        let localstorageData = {
          total: Appliance[0].upgrade_option[i].cost,
          data: Appliance[0].upgrade_option[i],
        };
        localStorage.setItem("appliances", JSON.stringify(localstorageData));
      }
    }
  };

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Appliances</span>
          </h3>
        </div>
      )}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <h4 style={{ marginBottom: "20px", marginTop: "30px" }}>
            Standard Option
          </h4>

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-120px">Miele Standard Package</th>
                <th className="min-w-120px">Model & Description</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Appliance[0]?.standard_option.packages.map(
                (data: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        {data.miele_standard_package}
                      </a>
                    </td>
                    <td className="text-start">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        {data.model_description}
                      </a>
                    </td>
                  </tr>
                )
              )}
            </tbody>
            {/* end::Table body */}
          </table>

          {page === "invoice" && (
            <div className="col-lg-6">
              <select
                className="form-select form-select-solid"
                data-kt-select2="true"
                data-placeholder="Direction"
                data-allow-clear="true"
                value={selected}
                onChange={handleOptionSelect}
                // disabled={true}
                // defaultValue={data.direction[0]}
              >
                {packageOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>

              {/* <FormInput style={{ marginTop: "20px" }} type='text' className='form-control form-control-solid' placeholder='Please enter comment here' onChange={(e) => handleComment(e.target.value)} /> */}
            </div>
          )}

          {selected && (
            <>
              <h4 style={{ marginBottom: "20px", marginTop: "50px" }}>
                Upgrade Option
              </h4>
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bolder text-muted">
                    {/* <th className="w-25px">
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      data-kt-check="true"
                      data-kt-check-target=".widget-9-check" />
                  </div>
                </th> */}
                    <th className="min-w-120px">Package type</th>
                    <th className="min-w-120px">Appliances</th>
                    <th className="min-w-120px">Model & Description</th>
                    <th className="min-w-120px">Cost</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {Appliance[0].upgrade_option.map(
                    (data: any, index: number) => (
                      <tr key={index}>
                        {data.package_type === selected && (
                          <>
                            {/* <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input widget-9-check"
                          type="checkbox"
                          value="1" />
                      </div>
                    </td> */}
                            <td>
                              {
                                <a
                                  href="#"
                                  className="text-dark fw-bolder text-hover-primary fs-6"
                                >
                                  {data.package_type}
                                </a>
                              }
                            </td>
                            <td>
                              {data.packages.map(
                                (packages: any, index: number) => (
                                  <a
                                    href="#"
                                    className="text-dark fw-bolder text-hover-primary fs-6"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      lineHeight: "50px",
                                    }}
                                    key={index}
                                  >
                                    {packages.appliances}
                                  </a>
                                )
                              )}
                            </td>
                            <td>
                              {data.packages.map(
                                (packages: any, index: number) => (
                                  <a
                                    href="#"
                                    className="text-dark fw-bolder text-hover-primary fs-6"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      lineHeight: "50px",
                                    }}
                                    key={index}
                                  >
                                    {packages.model_description}
                                  </a>
                                )
                              )}
                            </td>
                            <td>
                              {
                                <a
                                  href="#"
                                  className="text-dark fw-bolder text-hover-primary fs-6"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    lineHeight: "50px",
                                  }}
                                >
                                  ${data.cost?.toFixed(2)}
                                </a>
                              }
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </>
          )}

          <div>
            <a
              href="#"
              className="text-dark fw-bolder text-hover-primary"
              style={{
                display: "flex",
                marginTop: "30px",
              }}
            >
              Note -{" "}
              <span style={{ fontWeight: "bold" }}>
                Additional Modifications within Packages are charged $2,000 for
                each change made to a package
              </span>
            </a>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { AppliancesPage };

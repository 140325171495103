import CryptoJS from "crypto-js";
import { getLicense, setLicense } from "../modules/auth";

const SECRET_KEY: any = process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const hashLicense = (body: string) => {
  const hash = CryptoJS.SHA256(body).toString();
  const encryptedJSON = CryptoJS.AES.encrypt(body, SECRET_KEY).toString();
  const licenseData = {
    licenseJSON: encryptedJSON,
    hash: hash,
  };

  const licenseDataJSON = JSON.stringify(licenseData);
  setLicense(licenseDataJSON);
};

export const unHashLicense = () => {
  const localLicense = getLicense();

  if (localLicense) {
    // const hashResult = CryptoJS.SHA256(localLicense.licenseJSON).toString();
    // if (hashResult !== localLicense.hash) {
    //     console.log("License data has been tampered with!");
    // }
    const decryptedJSON = CryptoJS.AES.decrypt(
      localLicense.licenseJSON,
      SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
    const licenseObj = JSON.parse(decryptedJSON);

    return licenseObj;
  }
};

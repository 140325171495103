import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { TilesPage } from "./TilesPage";

const TilesPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tiles</PageTitle>
      <TilesPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default TilesPageWrapper;

import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPassword } from "../core/_requests";

const initialValues = {
  email_id: "",
  type: "user",
};

const forgotPasswordSchema = Yup.object().shape({
  email_id: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [buttonDisable, setButtonDisable] = useState(false); // disable button after submit
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setButtonDisable(true);
      const formData = new FormData();
      formData.append("email_id", values.email_id!);
      formData.append("type", values.type!);
      setSubmitting(true);
      try {
        await forgotPassword(formData);
        setHasErrors(false);
        setLoading(false);
      } catch (error) {
        setHasErrors(true);
        setSubmitting(false);
        setLoading(false);
        setButtonDisable(false);
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Invalid email! or Looks like there are some errors detected,
              please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-lg-15 alert alert-success">
            <div className="alert-text font-weight-bold">
              We have e-mailed your reset password link. Please check your email
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Email
          </label>
          <input
            type="email_id"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("email_id")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.email_id && formik.errors.email_id,
              },
              {
                "is-valid": formik.touched.email_id && !formik.errors.email_id,
              }
            )}
            name="email_id"
          />
          {formik.touched.email_id && formik.errors.email_id && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email_id}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={buttonDisable}
          >
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && (
              <span>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/admin">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid || buttonDisable}
            >
              Cancel
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}

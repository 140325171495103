import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { resetPassword } from "../core/_requests";
import jwt_decode from "jwt-decode";

const initialValues = {
  password: "",
};

const resetPasswordSchema = Yup.object().shape({
  //   password: Yup.string()
  //     .email('Wrong email format')
  //     .min(3, 'Minimum 3 symbols')
  //     .max(50, 'Maximum 50 symbols')
  //     .required('Email is required'),
});

export function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const resetPasswordToken: any = params.get("token");

  //console.log(resetPasswordToken)
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [buttonDisable, setButtonDisable] = useState(false); // disable button after submit
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setButtonDisable(true);
      let isCustomer: any = await jwt_decode(resetPasswordToken);
      const formData = new FormData();
      formData.append("password", values.password!);
      setSubmitting(true);
      try {
        await resetPassword(formData, resetPasswordToken);
        if (isCustomer?.data?.type === "customer") {
          setTimeout(() => {
            navigate("/auth/customer");
          }, 2000);
        } else {
          setTimeout(() => {
            navigate("/auth/admin");
          }, 2000);
        }
        setHasErrors(false);
      } catch (error) {
        setHasErrors(true);
        setSubmitting(false);
        setLoading(false);
        setButtonDisable(false);
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Create new password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            Enter your new password below.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Something went wrong! Please try later.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-lg-15 alert alert-success">
            <div className="alert-text font-weight-bold">
              Password updated successfully! Redirecting you to login page...
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Password
          </label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
            name="password"
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={buttonDisable}
          >
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && (
              <span>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}

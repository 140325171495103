import { useEffect, useState } from "react";
import { KTCardBody } from "../../../../../_metronic/helpers";
import {
  SyncProcoreCompany,
  SyncProcoreProject,
  SyncProcoreUser,
} from "./core/_request";
import moment from "moment";
import { Link } from "react-router-dom";
import ButtonLoader from "../../../../components/ButtonLoader";
import {
  toast,
  toastConfig,
  ToastOptions,
} from "../../../../utils/toastService";

const StatusPage = () => {
  const [companyLoading, setCompanyLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);

  const syncData = async (type: any, endpoint: any, storageKey: any) => {
    switch (type) {
      case "company":
        setCompanyLoading(true);
        break;
      case "project":
        setProjectLoading(true);
        break;
      case "user":
        setUserLoading(true);
        break;
      default:
        return;
    }

    try {
      const response = await endpoint(type);
      if (response && response.data) {
        const { data: getProcoreSyncdata } = response;
        localStorage.setItem(storageKey, JSON.stringify(getProcoreSyncdata));
        switch (type) {
          case "company":
            setCompanyLoading(false);
            break;
          case "project":
            setProjectLoading(false);
            break;
          case "user":
            setUserLoading(false);
            break;
          default:
            return;
        }
        toast.success(response.data.message, toastConfig as ToastOptions);
      } else {
        toast.error(
          "Response or data is undefined",
          toastConfig as ToastOptions
        );
      }
    } catch (error: any) {
      toast.error(error, toastConfig as ToastOptions);
    }
  };

  const SyncCompanyData = async () => {
    await syncData("company", SyncProcoreCompany, "syncProcoreCompanyData");
  };

  const SyncProjectData = async () => {
    await syncData("project", SyncProcoreProject, "syncProcoreProjectData");
  };

  const SyncUserData = async () => {
    await syncData("user", SyncProcoreUser, "syncProcoreUserData");
  };

  const storedCompanyResponseData: any = localStorage.getItem(
    "syncProcoreCompanyData"
  );
  const syncProcoreCompanyStorageData = JSON.parse(storedCompanyResponseData);

  const storedProjectResponseData: any = localStorage.getItem(
    "syncProcoreProjectData"
  );
  const syncProcoreProjectStorageData = JSON.parse(storedProjectResponseData);

  const storedUserResponseData: any = localStorage.getItem(
    "syncProcoreUserData"
  );
  const syncProcoreUserStorageData = JSON.parse(storedUserResponseData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await SyncCompanyData();
        await SyncProjectData();
        await SyncUserData();
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (
      !syncProcoreCompanyStorageData ||
      !syncProcoreProjectStorageData ||
      !syncProcoreUserStorageData
    ) {
      fetchData();
    }
  }, []); // eslint-disable-line

  return (
    <>
      <div className="bg-white">
        <KTCardBody>
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed no-footer gs-9 gy-9 gx-9">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Entity
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Name
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Updated Date
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Status
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Action
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {/* Sync Company : Starts */}
                <tr>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Company
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreCompanyStorageData?.result?.name
                        ? syncProcoreCompanyStorageData?.result?.name
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreCompanyStorageData?.result?.timestamp
                        ? moment(
                            syncProcoreCompanyStorageData?.result?.timestamp
                          ).format("DD MMMM YYYY")
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreCompanyStorageData?.result?.status
                        ? syncProcoreCompanyStorageData?.result?.status
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      <button
                        onClick={SyncCompanyData}
                        className="btn btn-primary"
                        disabled={syncProcoreCompanyStorageData ? true : false}
                      >
                        {!companyLoading && (
                          <span className="indicator-label">Sync</span>
                        )}
                        {companyLoading && <ButtonLoader />}
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Sync Company : Ends */}

                {/* Sync Project : Starts */}
                <tr>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      Project
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreProjectStorageData?.result?.status ===
                      "success" ? (
                        <Link to="/procore/projects-list">
                          Click here to view projects
                        </Link>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreProjectStorageData?.result?.timestamp
                        ? moment(
                            syncProcoreProjectStorageData?.result?.timestamp
                          ).format("DD MMMM YYYY")
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreProjectStorageData?.result?.status
                        ? syncProcoreProjectStorageData?.result?.status
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      <button
                        onClick={SyncProjectData}
                        className="btn btn-primary"
                      >
                        {!projectLoading && (
                          <span className="indicator-label">Sync</span>
                        )}
                        {projectLoading && <ButtonLoader />}
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Sync Project : Ends */}

                {/* Sync User : Starts */}
                <tr>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      User
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreUserStorageData?.result?.status ===
                      "success" ? (
                        <Link to="/procore/users-list">
                          Click here to view users
                        </Link>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreUserStorageData?.result?.timestamp
                        ? moment(
                            syncProcoreUserStorageData?.result?.timestamp
                          ).format("DD MMMM YYYY")
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      {syncProcoreUserStorageData?.result?.status
                        ? syncProcoreUserStorageData?.result?.status
                        : "-"}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      <button
                        onClick={SyncUserData}
                        className="btn btn-primary"
                      >
                        {!userLoading && (
                          <span className="indicator-label">Sync</span>
                        )}
                        {userLoading && <ButtonLoader />}
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Sync User : Ends */}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </div>
    </>
  );
};

export default StatusPage;

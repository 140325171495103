import { lazy, FC, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import CounterTopsPageWrapper from "../pages/counter-tops/CounterTopsPageWrapper";
import ElectricalPageWrapper from "../pages/electrical/ElectricalPageWrapper";
import TilesPageWrapper from "../pages/tiles/TilesPageWrapper";
import PlumbingFinishesPageWrapper from "../pages/plumbing-finishes/PlumbingFinishesPageWrapper";
import HardwoodPageWrapper from "../pages/hardwood/HardwoodPageWrapper";
import AppliancesPageWrapper from "../pages/appliances/AppliancesPageWrapper";
import SpecificationPageWrapper from "../pages/specifications/SpecificationPageWrapper";
import ConfigurationPageWrapper from "../pages/procore/components/configuration/ConfigurationPageWrapper";
import StatusPageWrapper from "../pages/procore/components/status/StatusPageWrapper";
import ListProjectsWrapper from "../pages/procore/components/status/projects/ListProjectsWrapper";
import SingleProject from "../pages/procore/components/status/projects/SingleProject";
import SingleUser from "../pages/procore/components/status/users/SingleUser";
import ListUsersWrapper from "../pages/procore/components/status/users/ListUsersWrapper";
import { useAuth } from "../modules/auth";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import { CustomerForgotPassword } from "../modules/auth/components/CustomerForgotPassword";
import { ResetPassword } from "../modules/auth/components/ResetPassword";
import {
  appliance,
  countertops,
  electrical,
  hardwood,
  plumbing_finishes,
  tiles,
} from "../modules/auth/core/_requests";
import {
  getAppliances,
  getCounterTops,
  getElectrical,
  getHardwood,
  getPlumbingFinishes,
  getTile,
  setAppliances,
  setCounterTops,
  setElectrical,
  setHardwood,
  setPlumbingFinishes,
  setTile,
} from "../modules/auth/core/InventoryHelpers";
import Loader from "../components/Loader";

const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const BuilderPageWrapper = lazy(
    () => import("../pages/layout-builder/BuilderPageWrapper")
  );
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const SitesPage = lazy(() => import("../pages/site/SitesPage"));
  const SuitesPage = lazy(() => import("../pages/suite/SuitesPage"));
  const InvoicePage = lazy(() => import("../pages/invoice/InvoicesPage"));
  const CustomersPage = lazy(() => import("../pages/customer/CustomersPage"));

  const { currentUser } = useAuth();

  const fetchInventory = async () => {
    setIsLoading(true);
    const electricalTable = await electrical();
    const plumbingFinishes = await plumbing_finishes();
    const tileData = await tiles();
    const hardwoodData = await hardwood();
    const AppliancesData = await appliance();
    const CounterTopsData = await countertops();
    setElectrical(electricalTable.data.result);
    setPlumbingFinishes(plumbingFinishes.data.result);
    setTile(tileData.data.result);
    setHardwood(hardwoodData.data.result);
    setAppliances(AppliancesData.data.result);
    setCounterTops(CounterTopsData.data.result);
    setIsLoading(false);
  };

  useEffect(() => {
    let localElectrical = getElectrical();
    let localPlumbingFinishes = getPlumbingFinishes();
    let localTile = getTile();
    let localHardwood = getHardwood();
    let localAppliances = getAppliances();
    let localCounterTops = getCounterTops();
    if (
      !localElectrical ||
      !localPlumbingFinishes ||
      !localTile ||
      !localHardwood ||
      !localAppliances ||
      !localCounterTops
    ) {
      fetchInventory();
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          {currentUser?.isCustomer ? (
            <Route
              path="auth/*"
              element={<Navigate to="/apps/invoices/add-invoice" />}
            />
          ) : (
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
          )}
          {/* Pages */}
          <Route path="dashboard" element={<DashboardWrapper />} />
          <Route path="countertops" element={<CounterTopsPageWrapper />} />
          <Route path="electrical" element={<ElectricalPageWrapper />} />
          <Route path="tiles" element={<TilesPageWrapper />} />
          <Route
            path="plumbing-finishes"
            element={<PlumbingFinishesPageWrapper />}
          />
          <Route path="hardwood" element={<HardwoodPageWrapper />} />
          <Route path="appliances" element={<AppliancesPageWrapper />} />
          <Route path="site/*" element={<SitesPage />} />
          <Route path="suite/*" element={<SuitesPage />} />
          <Route path="invoice" element={<InvoicePage />} />
          <Route path="specification" element={<SpecificationPageWrapper />} />
          <Route path="customer/*" element={<CustomersPage />} />
          <Route path="builder" element={<BuilderPageWrapper />} />
          <Route path="menu-test" element={<MenuTestPage />} />
          <Route
            path="configuration/*"
            element={<ConfigurationPageWrapper />}
          />
          <Route path="status/*" element={<StatusPageWrapper />} />
          <Route path="projects-list" element={<ListProjectsWrapper />} />
          <Route path="procore-project" element={<SingleProject />} />
          <Route path="procore-users-list" element={<ListUsersWrapper />} />
          <Route path="procore-user" element={<SingleUser />} />
          <Route path="reset_password" element={<ResetPassword />} />
          {/* Lazy Modules */}
          <Route
            path="crafted/pages/profile/*"
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path="crafted/pages/wizards/*"
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path="crafted/widgets/*"
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/account/profile/*"
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/chat/*"
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/sites/*"
            element={
              <SuspensedView>
                <SitesPage />
              </SuspensedView>
            }
          />

          <Route
            path="apps/suites/*"
            element={
              <SuspensedView>
                <SuitesPage />
              </SuspensedView>
            }
          />

          <Route
            path="procore/*"
            element={
              <SuspensedView>
                <ConfigurationPageWrapper />
                <StatusPageWrapper />
                <ListProjectsWrapper />
                <ListUsersWrapper />
              </SuspensedView>
            }
          />

          <Route
            path="apps/customers/*"
            element={
              <SuspensedView>
                <CustomersPage />
              </SuspensedView>
            }
          />

          <Route
            path="apps/invoices/*"
            element={
              <SuspensedView>
                <InvoicePage />
              </SuspensedView>
            }
          />

          <Route
            path="auth/*"
            element={
              <SuspensedView>
                <ForgotPassword />
                <CustomerForgotPassword />
                <ResetPassword />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
    );
  }
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

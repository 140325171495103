import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";
import ListProjects from "./ListProjects";

const projectListBreadcrumbs: Array<PageLink> = [
  {
    title: "Status",
    path: "/procore/status",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ListProjectsWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="projects-list"
          element={
            <>
              <PageTitle breadcrumbs={projectListBreadcrumbs}>
                Projects
              </PageTitle>
              <ListProjects />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/procore/projects-list" />} />
    </Routes>
  );
};

export default ListProjectsWrapper;

import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl();
  const { access, currentUser } = useAuth();

  return (
    <>
      {access?.access?.features?.dashboard ? (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/graphs/gra002.svg"
          title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
          fontIcon="bi-app-indicator"
        />
      ) : null}

      {access?.access?.features?.switch_tab?.authorized ? (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/communication/com014.svg"
          title="Switch User"
        />
      ) : null}

      {access?.access?.features?.site?.authorized ? (
        <AsideMenuItem
          to="/site"
          icon="/media/icons/duotune/maps/map009.svg"
          title="Site"
        />
      ) : null}

      {access?.access?.features?.suite?.authorized ? (
        <AsideMenuItem
          to="/suite"
          icon="/media/icons/duotune/general/gen001.svg"
          title="Suite"
        />
      ) : null}

      {access?.access?.features?.suite?.authorized ? (
        <AsideMenuItem
          to="/customer"
          icon="/media/icons/duotune/communication/com013.svg"
          title="Customer"
        />
      ) : null}

      {access?.access?.features?.inventories?.authorized ? (
        <AsideMenuItemWithSub
          to="/inventory"
          title="Inventory"
          icon="/media/icons/duotune/text/txt009.svg"
          fontIcon="bi-person"
        >
          {access?.access?.features?.electrical?.authorized ? (
            <AsideMenuItem
              to="/electrical"
              icon="/media/icons/duotune/technology/teh001.svg"
              title="Electrical"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.plumbing_finishes?.authorized ? (
            <AsideMenuItem
              to="/plumbing-finishes"
              icon="/media/icons/duotune/coding/cod009.svg"
              title="Plumbing finishes"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.tile?.authorized ? (
            <AsideMenuItem
              to="/tiles"
              icon="/media/icons/duotune/abstract/abs010.svg"
              title="Tile"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.hardwood?.authorized ? (
            <AsideMenuItem
              to="/hardwood"
              icon="/media/icons/duotune/abstract/abs045.svg"
              title="Hardwood"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.appliances?.authorized ? (
            <AsideMenuItem
              to="/appliances"
              icon="/media/icons/duotune/electronics/elc003.svg"
              title="Appliances"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.countertops?.authorized ? (
            <AsideMenuItem
              to="/countertops"
              icon="/media/icons/duotune/technology/teh009.svg"
              title="Countertops"
              hasBullet={true}
            />
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}

      {access?.access?.features?.invoice?.authorized ? (
        <AsideMenuItemWithSub
          to="/invoice"
          title="Finishing"
          icon="/media/icons/duotune/ecommerce/ecm008.svg"
          fontIcon="bi-person"
        >
          {access?.access?.features?.choose_finishing?.authorized ? (
            <AsideMenuItem
              to="/apps/invoices/add-invoice"
              icon="/media/icons/duotune/files/fil005.svg"
              title="Choose Finishing"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.list_invoices?.authorized ? (
            <AsideMenuItem
              to="/apps/invoices/invoice"
              icon="/media/icons/duotune/text/txt010.svg"
              title="List Finishing"
              hasBullet={true}
            />
          ) : null}

          {/* View Invoice & View Specification for Sales rep & other admins */}
          {currentUser?.isCustomer ? null : (
            <>
              <AsideMenuItem
                to="/apps/invoices/view-invoice"
                icon="/media/icons/duotune/files/fil004.svg"
                title="View Finishing"
                hasBullet={true}
              />
              <AsideMenuItem
                to="/apps/invoices/view-specification"
                icon="/media/icons/duotune/coding/cod002.svg"
                title="View Specification"
                hasBullet={true}
              />
            </>
          )}
        </AsideMenuItemWithSub>
      ) : null}

      {currentUser?.isCustomer && (
        <>
          <AsideMenuItem
            to="/apps/invoices/add-invoice"
            icon="/media/icons/duotune/files/fil005.svg"
            title="Choose Finishing"
            hasBullet={true}
          />
          <AsideMenuItem
            to={"/apps/invoices/invoice-details/" + currentUser?.customer_id}
            icon="/media/icons/duotune/files/fil004.svg"
            title="View Finishing"
            hasBullet={true}
          />
          <AsideMenuItem
            to={
              "/apps/invoices/specification-details/" + currentUser?.customer_id
            }
            icon="/media/icons/duotune/coding/cod002.svg"
            title="View Specification"
            hasBullet={true}
          />
        </>
      )}

      {/* Procore Menu Starts  */}
      {access?.access?.features?.procore?.authorized ? (
        <AsideMenuItemWithSub
          to="/procore"
          title="Procore"
          icon="/media/icons/duotune/procore/procore.svg"
          fontIcon="bi-person"
        >
          {access?.access?.features?.procore_configuration?.authorized ? (
            <AsideMenuItem
              to="/configuration"
              icon="/media/icons/duotune/coding/cod009.svg"
              title="Configuration"
              hasBullet={true}
            />
          ) : null}

          {access?.access?.features?.procore_status?.authorized ? (
            <AsideMenuItem
              to="/status"
              icon="/media/icons/duotune/coding/cod005.svg"
              title="Status"
              hasBullet={true}
            />
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}
      {/* Procore Menu Ends  */}
    </>
  );
}

import React, { FC } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { ConterTopsPage } from "./CounterTopsPage";

const CounterTopsPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Counter Tops</PageTitle>
      <ConterTopsPage className="mb-5 mb-xl-10" />
    </>
  );
};

export default CounterTopsPageWrapper;

import axios from "axios";
import { GetProcoreConfiguration, ProcoreConfiguration } from "./_models";
import { ID } from "../../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_PROCORE_API_URL;

export const SAVE_PROCORE_CONFIGURATION_URL = `${API_URL}/procore/configuration`;
export const GET_PROCORE_CONFIGURATION_URL = `${API_URL}/procore/configuration`;

const procoreHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type",
};

export function saveProcoreConfiguration(
  client_id: string,
  client_secret: string,
  company_id: string
) {
  return axios.post<ProcoreConfiguration>(
    SAVE_PROCORE_CONFIGURATION_URL,
    {
      client_id,
      client_secret,
      company_id,
    },
    {
      headers: procoreHeaders,
    }
  );
}

export function getProcoreConfiguration(id: ID) {
  return axios.get<GetProcoreConfiguration>(
    `${GET_PROCORE_CONFIGURATION_URL}/${id}`,
    {
      headers: procoreHeaders,
    }
  );
}

import { AuthModel, UserAccesModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";

// set, get, remove auth
const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  const data = {
    result: auth.result,
  };

  try {
    localStorage.setItem("auth", JSON.stringify(data));
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("auth");
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("auth");
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

// set, get, remove user access
const setUserAccess = (access: UserAccesModel) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("access", JSON.stringify(access));
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getUserAccess = (): UserAccesModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("access");
  if (!lsValue) {
    return;
  }

  try {
    const access: UserAccesModel = JSON.parse(lsValue) as UserAccesModel;
    if (access) {
      // You can easily check access_token expiration also
      return access;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const removeUserAcces = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("access");
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

// set, get, remove license
const setLicense = (license: string) => {
  if (!localStorage) {
    return;
  }

  const data = license;

  try {
    localStorage.setItem("license", data);
  } catch (error) {
    console.error("LICENSE LOCAL STORAGE SAVE ERROR", error);
  }
};

const getLicense = () => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("license");
  if (!lsValue) {
    return;
  }

  try {
    const license = JSON.parse(lsValue);
    if (license) {
      return license;
    }
  } catch (error) {
    console.error("LICENSE LOCAL STORAGE PARSE ERROR", error);
  }
};

const removeLicense = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("license");
  } catch (error) {
    console.error("LICENSE LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: {
      headers: { Authorization: string; "request-id": "request-id" };
    }) => {
      const auth = getAuth();
      if (auth && auth.result) {
        config.headers.Authorization = `${auth.result}`;
        config.headers["request-id"] = "request-id"; //TODO: add unique request id on each request
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export {
  setAuth,
  getAuth,
  removeAuth,
  setUserAccess,
  getUserAccess,
  removeUserAcces,
  setLicense,
  getLicense,
  removeLicense,
  AUTH_LOCAL_STORAGE_KEY,
};

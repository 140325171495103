/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getTile, setTile } from "../../modules/auth/core/InventoryHelpers";
import { KTSVG } from "../../../_metronic/helpers";
import { Button, Modal } from "react-bootstrap";
import TilesInteractive from "./interactive_module/TilesInteractive";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
  tiles?: any;
};

let total: number = 0;

const TilesPage: React.FC<Props> = ({
  className,
  page,
  invoiceType,
  tiles,
}) => {
  const [Tiles, setTileData]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);

  const refactorDefaultTileSelection = (tiles: any) => {
    let defaultSelectedTiles = tiles.map((tile: any) => {
      const newTile = {
        ...tile,
        sf_cost: tile.option_selected[0].sf_cost,
        selected_tiles_option: tile.option_selected[0].tile_selection,
        cost: tile.area_sf * tile.option_selected[0].sf_cost,
        selectedOption: tile.option_selected[0].option,
      };

      return newTile;
    });

    return defaultSelectedTiles;
  };

  const fetchTileData = async () => {
    let tileData = await tiles();
    setTile(tileData.data.result);
    let localTile = getTile();
    let defaultSelectedTiles = refactorDefaultTileSelection(localTile);
    setTileData(defaultSelectedTiles);
  };

  useEffect(() => {
    let localTile = getTile();
    if (invoiceTypeMode === "edit") {
      let localStorageTiles: any = localStorage.getItem("tiles");
      let tempSavedTiles: any = JSON.parse(localStorageTiles);
      total = tempSavedTiles?.total;
      setTileData(tempSavedTiles?.data);
    } else {
      if (!localTile) {
        fetchTileData();
      } else {
        if (tiles && tiles.length) {
          let defaultSelectedTiles = refactorDefaultTileSelection(tiles);
          setTileData(defaultSelectedTiles);
        } else {
          let defaultSelectedTiles = refactorDefaultTileSelection(localTile);
          setTileData(defaultSelectedTiles);
        }
      }
    }
  }, [tiles]); // eslint-disable-line

  const handleChange = (event: string, index: number) => {
    let clone = [...Tiles];
    let obj = clone[index];
    let tempTotal: number = 0;
    for (let i = 0; i < obj.option_selected.length; i++) {
      if (obj.option_selected[i].option === event) {
        obj.sf_cost = obj.option_selected[i].sf_cost;
        obj.selected_tiles_option = obj.option_selected[i].tile_selection;
        obj.cost = obj.area_sf * obj.sf_cost;
        obj.selectedOption = obj.option_selected[i].option;
        obj.option_selected[i].selected = true;
      } else {
        obj.cost = obj.area_sf * obj.sf_cost;
        obj.option_selected[i].selected = false;
      }
    }
    clone[index] = obj;
    setTileData([...clone]);

    let localstorageData = {
      total: total,
      data: Tiles,
    };
    localStorage.setItem("tiles", JSON.stringify(localstorageData));

    let localStorageTiles: any = localStorage.getItem("tiles");
    let tempSavedTiles: any = JSON.parse(localStorageTiles);
    let savedTiles: any = tempSavedTiles.data;
    for (let i = 0; i < savedTiles.length; i++) {
      if (savedTiles[i].cost && savedTiles[i].cost > 0) {
        if (savedTiles[i]?.cost) {
          tempTotal = tempTotal + savedTiles[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedTiles,
      };
      localStorage.setItem("tiles", JSON.stringify(localstorageData));
    }
  };

  const handleTileSelection = (event: string, index: number) => {
    let clone = [...Tiles];
    let obj = clone[index];
    let tempTotal: number = 0;
    for (let i = 0; i < obj.selected_tiles_option.length; i++) {
      if (obj.selected_tiles_option[i].tile_selection_option === event) {
        obj.selectedTile = obj.selected_tiles_option[i].tile_selection_option;
      }
    }
    clone[index] = obj;
    setTileData([...clone]);

    let localstorageData = {
      total: total,
      data: Tiles,
    };
    localStorage.setItem("tiles", JSON.stringify(localstorageData));

    let localStorageTiles: any = localStorage.getItem("tiles");
    let tempSavedTiles: any = JSON.parse(localStorageTiles);
    let savedTiles: any = tempSavedTiles.data;
    for (let i = 0; i < savedTiles.length; i++) {
      if (savedTiles[i].cost && savedTiles[i].cost > 0) {
        if (savedTiles[i]?.cost) {
          tempTotal = tempTotal + savedTiles[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedTiles,
      };
      localStorage.setItem("tiles", JSON.stringify(localstorageData));
    }
  };

  const calculateCost = (event: string, index: number) => {
    const area: number = Number(event);
    let clone = [...Tiles];
    let obj = clone[index];
    obj.area_sf = area;
    obj.cost = obj.sf_cost * area;
    let tempTotal: number = 0;

    clone[index] = obj;
    setTileData([...clone]);

    let localstorageData = {
      total: total,
      data: Tiles,
    };
    localStorage.setItem("tiles", JSON.stringify(localstorageData));

    let localStorageTiles: any = localStorage.getItem("tiles");
    let tempSavedTiles: any = JSON.parse(localStorageTiles);
    let savedTiles: any = tempSavedTiles.data;
    for (let i = 0; i < savedTiles.length; i++) {
      if (savedTiles[i].cost && savedTiles[i].cost > 0) {
        if (savedTiles[i]?.cost) {
          tempTotal = tempTotal + savedTiles[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedTiles,
      };
      localStorage.setItem("tiles", JSON.stringify(localstorageData));
    }
  };

  // Search Function : Begins

  const [query, setQuery] = useState("");

  // Search Function : Ends

  // Interactive Module Pop Up : Starts

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleModalClose = () => {
    let tempTotal: number = 0;
    let localStorageTiles: any = localStorage.getItem("tiles");
    let tempSavedTiles: any = JSON.parse(localStorageTiles);
    let savedTiles: any = tempSavedTiles.data;
    for (let i = 0; i < savedTiles.length; i++) {
      if (savedTiles[i].cost && savedTiles[i].cost > 0) {
        if (savedTiles[i]?.cost) {
          tempTotal = tempTotal + savedTiles[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedTiles,
      };
      localStorage.setItem("tiles", JSON.stringify(localstorageData));
    }
    setTileData(tempSavedTiles?.data);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // Interactive Module Pop Up : Ends

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Tiles</span>
          </h3>
          {/* Interactive Modal : Begins */}
          <Button variant="primary" onClick={handleShow}>
            Choose Interactive Mode
          </Button>
          <Modal show={show} onHide={handleClose} fullscreen={true}>
            <Modal.Header closeButton>
              <Modal.Title>Tiles Interactive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Tiles.length ? (
                <TilesInteractive
                  tiles={Tiles}
                  handleModalClose={handleModalClose}
                />
              ) : null}
            </Modal.Body>
          </Modal>
          {/* Interactive Modal : Ends */}
        </div>
      )}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin:: Table Search Box */}
        {page === "invoice" ? null : (
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1 pt-10 pb-10">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Search tiles"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        )}
        {/* end:: Table Search Box */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-120px">Area</th>
                <th className="min-w-120px">Option Selected</th>
                <th className="min-w-120px">Tile Selection</th>
                <th className="min-w-120px">SF cost</th>

                {page === "invoice" && (
                  <>
                    <th className="min-w-120px">Area (SF)</th>
                    <th className="min-w-120px" style={{ width: "12%" }}>
                      Cost
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Tiles?.length &&
                Tiles?.filter((data: { area: string }) =>
                  data?.area.toLowerCase().includes(query)
                ).map((data: any, index: number) => (
                  <tr key={data?._id}>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        {data?.area}
                      </a>
                    </td>

                    <td>
                      <select
                        className="form-select form-select-solid"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        defaultValue={
                          data?.selectedOption ||
                          data?.option_selected[0].option
                        }
                        onChange={(e) => handleChange(e.target.value, index)}
                      >
                        {data?.option_selected.map(
                          (options: any, index: number) => (
                            <option
                              key={index}
                              value={options.option}
                              selected={options.option === data?.selectedOption}
                            >
                              {options.option}
                            </option>
                          )
                        )}
                      </select>
                    </td>

                    <td>
                      <select
                        className="form-select form-select-solid"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        defaultValue={
                          data?.selectedTile ||
                          data?.option_selected[0].tile_selection[0]
                            .tile_selection_option
                        }
                        onChange={(e) =>
                          handleTileSelection(e.target.value, index)
                        }
                      >
                        {data?.selected_tiles_option
                          ? data?.selected_tiles_option.map(
                              (options: any, index: number) => (
                                <option
                                  key={index}
                                  value={options?.tile_selection_option}
                                  selected={
                                    options?.tile_selection_option ===
                                    data?.selectedTile
                                  }
                                >
                                  {options.tile_selection_option}
                                </option>
                              )
                            )
                          : data?.option_selected[0].tile_selection.map(
                              (options: any, index: number) => (
                                <option
                                  key={index}
                                  value={options.tile_selection_option}
                                >
                                  {options.tile_selection_option}
                                </option>
                              )
                            )}
                      </select>
                    </td>

                    {/* <td className="text-start">
                    <select
                      className="form-select form-select-solid"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      defaultValue={data?.tile_selection[0]}
                      onChange={(e) => handleChange(e.target.value, index)}
                    >
                      {data?.tile_selection.map((selection: any, index: number) => (
                        <option key={index} value={selection} >{selection}</option>
                      ))}
                    </select>
                  </td> */}

                    <td className="text-start">
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                      >
                        ${data?.sf_cost || data?.option_selected[0].sf_cost}
                      </a>
                    </td>

                    {page === "invoice" && (
                      <>
                        <td className="text-center">
                          <input
                            type="number"
                            min={0}
                            style={{ marginBottom: "5px" }}
                            // data-kt-user-table-filter='search'
                            className="form-control form-control-solid w-150px ps-14"
                            placeholder="area"
                            // value={fixture.unit || 0}
                            value={data?.area_sf || ""}
                            onChange={(e) =>
                              calculateCost(e.target.value, index)
                            }
                          />
                        </td>

                        <td className="text-start">
                          <a
                            href="#"
                            className="text-dark fw-bolder text-hover-primary fs-6"
                          >
                            ${data?.cost ? data?.cost?.toFixed(2) : 0}
                          </a>
                        </td>
                      </>
                    )}
                  </tr>
                ))}

              {page === "invoice" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 600 }} className="text-center">
                      Total
                    </td>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                        style={{ paddingLeft: "2.5%" }}
                      >
                        ${total?.toFixed(2) || 0}
                      </a>
                    </td>
                  </tr>
                  {/* <tr style={{ marginTop: "10px" }}>
                  <td colSpan={3}>
                    <FormInput type='text' className='form-control form-control-solid' placeholder='Please enter comment here' onChange={(e) => handleComment(e.target.value)} />
                  </td>
                </tr> */}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TilesPage };

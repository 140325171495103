import {
  AppliancesModel,
  CounterTopsModel,
  ElectricalModel,
  HardwoodModel,
  PlumbingFinishesModel,
  TileModel,
} from "./_models";

// Hardwood Helper
const getHardwood = (): HardwoodModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("hardwood_inventory");
  if (!lsValue) {
    return;
  }

  try {
    const hardwood: HardwoodModel = JSON.parse(lsValue) as HardwoodModel;
    if (hardwood) {
      return hardwood;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setHardwood = (hardwood: Array<HardwoodModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("hardwood_inventory", JSON.stringify(hardwood));
  } catch (error) {
    console.error("HARDWOOD LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeHardwood = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("hardwood_inventory");
  } catch (error) {
    console.error("HARDWOOD LOCAL STORAGE REMOVE ERROR", error);
  }
};

// Electrical Helper
const getElectrical = (): ElectricalModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("electrical_inventory");
  if (!lsValue) {
    return;
  }

  try {
    const electrical: ElectricalModel = JSON.parse(lsValue) as ElectricalModel;
    if (electrical) {
      return electrical;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setElectrical = (electrical: Array<ElectricalModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("electrical_inventory", JSON.stringify(electrical));
  } catch (error) {
    console.error("ELECTRICAL LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeElectrical = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("electrical_inventory");
  } catch (error) {
    console.error("ELECTRICAL LOCAL STORAGE REMOVE ERROR", error);
  }
};

// Plumbing Finishes Helper

const getPlumbingFinishes = (): PlumbingFinishesModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(
    "plumbing_finishes_inventory"
  );
  if (!lsValue) {
    return;
  }

  try {
    const plumbing: PlumbingFinishesModel = JSON.parse(
      lsValue
    ) as PlumbingFinishesModel;
    if (plumbing) {
      return plumbing;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setPlumbingFinishes = (plumbing: Array<PlumbingFinishesModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(
      "plumbing_finishes_inventory",
      JSON.stringify(plumbing)
    );
  } catch (error) {
    console.error("PLUMBING FINISHES LOCAL STORAGE SAVE ERROR", error);
  }
};

const removePlumbingFinishes = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("plumbing_finishes_inventory");
  } catch (error) {
    console.error("PLUMBING FINISHES LOCAL STORAGE REMOVE ERROR", error);
  }
};

// Counter Tops Helper

const getCounterTops = (): CounterTopsModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("counter_tops_inventory");
  if (!lsValue) {
    return;
  }

  try {
    const countertops: CounterTopsModel = JSON.parse(
      lsValue
    ) as CounterTopsModel;
    if (countertops) {
      return countertops;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setCounterTops = (countertops: Array<CounterTopsModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("counter_tops_inventory", JSON.stringify(countertops));
  } catch (error) {
    console.error("COUNTER TOPS LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeCounterTops = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("counter_tops_inventory");
  } catch (error) {
    console.error("COUNTER TOPS LOCAL STORAGE REMOVE ERROR", error);
  }
};

// Tile Helper

const getTile = (): TileModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("tile_inventory");
  if (!lsValue) {
    return;
  }

  try {
    const tile: TileModel = JSON.parse(lsValue) as TileModel;
    if (tile) {
      return tile;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setTile = (tile: Array<TileModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("tile_inventory", JSON.stringify(tile));
  } catch (error) {
    console.error("TILE LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeTile = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("tile_inventory");
  } catch (error) {
    console.error("TILE LOCAL STORAGE REMOVE ERROR", error);
  }
};

// Appliances Helper
const getAppliances = (): AppliancesModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem("appliances_inventory");
  if (!lsValue) {
    return;
  }

  try {
    const appliances: AppliancesModel = JSON.parse(lsValue) as AppliancesModel;
    if (appliances) {
      return appliances;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAppliances = (appliances: Array<AppliancesModel>) => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem("appliances_inventory", JSON.stringify(appliances));
  } catch (error) {
    console.error("APPLIANCES LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAppliances = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem("appliances_inventory");
  } catch (error) {
    console.error("APPLIANCES LOCAL STORAGE REMOVE ERROR", error);
  }
};

export {
  getHardwood,
  setHardwood,
  removeHardwood,
  getElectrical,
  setElectrical,
  removeElectrical,
  getPlumbingFinishes,
  setPlumbingFinishes,
  removePlumbingFinishes,
  getCounterTops,
  setCounterTops,
  removeCounterTops,
  getTile,
  setTile,
  removeTile,
  getAppliances,
  setAppliances,
  removeAppliances,
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  getHardwood,
  setHardwood,
} from "../../modules/auth/core/InventoryHelpers";
import { KTSVG } from "../../../_metronic/helpers";
import { Button, Modal } from "react-bootstrap";
import HardwoodInteractive from "./interactive_module/HardwoodInteractive";

type Props = {
  className: string;
  page?: string;
  invoiceType?: string;
  hardwood?: any;
};

let total: number = 0;

const HardwoodPage: React.FC<Props> = ({
  className,
  page,
  invoiceType,
  hardwood,
}) => {
  const [hardwoodData, setHardwoodData]: any = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceTypeMode, setInvoiceTypeMode]: any =
    React.useState(invoiceType);

  const refactorDefaultHardwoodSelection = (hardwoods: any) => {
    let defaultSelectedHardwood = hardwoods.map((hardwood: any) => {
      const newHardwood = {
        ...hardwood,
        sf_cost: hardwood.option_selected[0].sf_cost,
        selected_wood: hardwood.option_selected[0].wood_selection,
        cost: hardwood.sf_cost * hardwood.area_sf || 0,
        selectedOption: hardwood.option_selected[0].option,
      };

      return newHardwood;
    });

    return defaultSelectedHardwood;
  };

  const fetchHardwoodData = async () => {
    let hardwoodData = await hardwood();
    setHardwood(hardwoodData.data.result);
    let localHardwood = getHardwood();
    let defaultSelectedHardwood =
      refactorDefaultHardwoodSelection(localHardwood);
    setHardwoodData(defaultSelectedHardwood);
  };

  useEffect(() => {
    let localHardwood = getHardwood();
    if (invoiceTypeMode === "edit") {
      let localStorageHardwoods: any = localStorage.getItem("hardwood");
      let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
      total = tempSavedHardwoods?.total;
      setHardwoodData(tempSavedHardwoods?.data);
    } else {
      if (!localHardwood) {
        fetchHardwoodData();
      } else {
        if (hardwood && hardwood.length) {
          let defaultSelectedHardwood =
            refactorDefaultHardwoodSelection(hardwood);
          setHardwoodData(defaultSelectedHardwood);
        } else {
          let defaultSelectedHardwood =
            refactorDefaultHardwoodSelection(localHardwood);
          setHardwoodData(defaultSelectedHardwood);
        }
      }
    }
  }, [hardwood]); // eslint-disable-line

  const handleChange = (event: string, index: number) => {
    let clone = [...hardwoodData];
    let obj = clone[index];
    let tempTotal: number = 0;
    for (let i = 0; i < obj.option_selected.length; i++) {
      if (obj.option_selected[i].option === event) {
        obj.sf_cost = obj.option_selected[i].sf_cost;
        obj.selected_wood = obj.option_selected[i].wood_selection;
        // obj.cost = 0;
        obj.cost = obj.sf_cost * obj.area_sf;
        // obj.cost = obj.sf_cost * obj.area_sf;
        obj.selectedOption = obj.option_selected[i].option;
        obj.option_selected[i].selected = true;
      } else {
        obj.option_selected[i].selected = false;
      }
    }
    clone[index] = obj;
    setHardwoodData([...clone]);

    let localstorageData = {
      total: total,
      data: hardwoodData,
    };
    localStorage.setItem("hardwood", JSON.stringify(localstorageData));

    let localStorageHardwoods: any = localStorage.getItem("hardwood");
    let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
    let savedHardwoods: any = tempSavedHardwoods.data;
    for (let i = 0; i < savedHardwoods.length; i++) {
      if (savedHardwoods[i].cost && savedHardwoods[i].cost > 0) {
        if (savedHardwoods[i]?.cost) {
          tempTotal = tempTotal + savedHardwoods[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedHardwoods,
      };
      localStorage.setItem("hardwood", JSON.stringify(localstorageData));
    }
  };

  const handleWoodSelection = (event: string, index: number) => {
    let clone = [...hardwoodData];
    let obj = clone[index];
    let tempTotal: number = 0;
    for (let i = 0; i < obj.selected_wood.length; i++) {
      if (obj.selected_wood[i].wood_selection_option === event) {
        obj.selectedWood = obj.selected_wood[i].wood_selection_option;
      }
    }
    clone[index] = obj;
    setHardwoodData([...clone]);

    let localstorageData = {
      total: total,
      data: hardwoodData,
    };
    localStorage.setItem("hardwood", JSON.stringify(localstorageData));

    let localStorageHardwoods: any = localStorage.getItem("hardwood");
    let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
    let savedHardwoods: any = tempSavedHardwoods.data;
    for (let i = 0; i < savedHardwoods.length; i++) {
      if (savedHardwoods[i].cost && savedHardwoods[i].cost > 0) {
        if (savedHardwoods[i]?.cost) {
          tempTotal = tempTotal + savedHardwoods[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedHardwoods,
      };
      localStorage.setItem("hardwood", JSON.stringify(localstorageData));
    }
  };

  const handleDirection = (event: string, index: number) => {
    let clone = [...hardwoodData];
    let obj = clone[index];
    let tempTotal: number = 0;
    for (let i = 0; i < obj.direction.length; i++) {
      if (obj.direction[i] === event) {
        obj.selectedDirection = obj.direction[i];
      }
    }
    clone[index] = obj;
    setHardwoodData([...clone]);

    let localstorageData = {
      total: total,
      data: hardwoodData,
    };
    localStorage.setItem("hardwood", JSON.stringify(localstorageData));

    let localStorageHardwoods: any = localStorage.getItem("hardwood");
    let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
    let savedHardwoods: any = tempSavedHardwoods.data;
    for (let i = 0; i < savedHardwoods.length; i++) {
      if (savedHardwoods[i].cost && savedHardwoods[i].cost > 0) {
        if (savedHardwoods[i]?.cost) {
          tempTotal = tempTotal + savedHardwoods[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedHardwoods,
      };
      localStorage.setItem("hardwood", JSON.stringify(localstorageData));
    }
  };

  const calculateCost = (event: string, index: number) => {
    const area: number = Number(event);
    let clone = [...hardwoodData];
    let obj = clone[index];
    obj.area_sf = area;
    obj.cost = obj.sf_cost * area;
    let tempTotal: number = 0;

    clone[index] = obj;
    setHardwoodData([...clone]);

    // let selectedItem = [];

    // for (let i = 0; i < hardwoodData.length; i++) {
    //   selectedItem.push(hardwoodData[i])
    //   let localstorageData = {
    //     total: total,
    //     data: selectedItem
    //   }
    //   localStorage.setItem('hardwood', JSON.stringify(localstorageData));
    // }

    let localstorageData = {
      total: total,
      data: hardwoodData,
    };
    localStorage.setItem("hardwood", JSON.stringify(localstorageData));

    let localStorageHardwoods: any = localStorage.getItem("hardwood");
    let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
    let savedHardwoods: any = tempSavedHardwoods.data;
    for (let i = 0; i < savedHardwoods.length; i++) {
      if (savedHardwoods[i].cost && savedHardwoods[i].cost > 0) {
        if (savedHardwoods[i]?.cost) {
          tempTotal = tempTotal + savedHardwoods[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedHardwoods,
      };
      localStorage.setItem("hardwood", JSON.stringify(localstorageData));
    }
  };

  // Search Function : Begins

  const [query, setQuery] = useState("");

  // Search Function : Ends

  // Interactive Module Pop Up : Starts

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleModalClose = () => {
    let tempTotal: number = 0;
    let localStorageHardwoods: any = localStorage.getItem("hardwood");
    let tempSavedHardwoods: any = JSON.parse(localStorageHardwoods);
    let savedHardwoods: any = tempSavedHardwoods.data;
    for (let i = 0; i < savedHardwoods.length; i++) {
      if (savedHardwoods[i].cost && savedHardwoods[i].cost > 0) {
        if (savedHardwoods[i]?.cost) {
          tempTotal = tempTotal + savedHardwoods[i]?.cost;
        }
      }
    }

    total = tempTotal;

    if (total) {
      let localstorageData = {
        total: total,
        data: savedHardwoods,
      };
      localStorage.setItem("hardwood", JSON.stringify(localstorageData));
    }
    setHardwoodData(tempSavedHardwoods?.data);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // Interactive Module Pop Up : Ends

  return (
    <div
      className={`card ${className}`}
      style={{ maxWidth: "1600px !important" }}
    >
      {/* begin::Header */}
      {page === "invoice" && (
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Hardwood</span>
          </h3>
          {/* Interactive Modal : Begins */}
          <Button variant="primary" onClick={handleShow}>
            Choose Interactive Mode
          </Button>
          <Modal show={show} onHide={handleClose} fullscreen={true}>
            <Modal.Header closeButton>
              <Modal.Title>Hardwood Interactive</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {hardwoodData.length ? (
                <HardwoodInteractive
                  hardwoods={hardwoodData}
                  handleModalClose={handleModalClose}
                />
              ) : null}
            </Modal.Body>
          </Modal>
          {/* Interactive Modal : Ends */}
        </div>
      )}
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin:: Table Search Box */}
        {page === "invoice" ? null : (
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1 pt-10 pb-10">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Search hardwood"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        )}
        {/* end:: Table Search Box */}
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}

          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-160px">Area</th>
                <th className="min-w-180px">Direction</th>
                <th className="min-w-120px">Option selected</th>
                <th className="min-w-60px">Wood selection</th>
                <th className="min-w-180px">SF cost</th>

                {page === "invoice" && (
                  <>
                    <th className="min-w-120px">Area (SF)</th>
                    <th className="min-w-120px" style={{ width: "12%" }}>
                      Cost
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {hardwoodData?.length &&
                hardwoodData
                  ?.filter((data: { area: string }) =>
                    data?.area.toLowerCase().includes(query)
                  )
                  .map((data: any, index: any) => (
                    <tr key={data?._id}>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          {data?.area}
                        </a>
                      </td>

                      <td className="text-start">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Direction"
                          data-allow-clear="true"
                          defaultValue={
                            data?.selectedDirection || data?.direction[0]
                          }
                          onChange={(e) =>
                            handleDirection(e.target.value, index)
                          }
                        >
                          {data?.direction.map(
                            (direction: any, index: number) => (
                              <option
                                key={index}
                                value={direction}
                                selected={direction === data?.selectedDirection}
                              >
                                {direction}
                              </option>
                            )
                          )}
                        </select>
                      </td>

                      <td className="text-start">
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          defaultValue={
                            data?.selectedOption ||
                            data?.option_selected[0].option
                          }
                          onChange={(e) => handleChange(e.target.value, index)}
                        >
                          {data?.option_selected.map(
                            (option: any, index: number) => (
                              <option
                                key={index}
                                value={option.option}
                                selected={
                                  option?.option === data?.selectedOption
                                }
                              >
                                {option.option}
                              </option>
                            )
                          )}
                        </select>
                      </td>

                      <td>
                        <select
                          className="form-select form-select-solid"
                          data-kt-select2="true"
                          data-placeholder="Wood selection"
                          data-allow-clear="true"
                          defaultValue={
                            data?.selectedWood ||
                            data?.option_selected[0].wood_selection[0]
                              .wood_selection_option
                          }
                          onChange={(e) =>
                            handleWoodSelection(e.target.value, index)
                          }
                        >
                          {data?.selected_wood
                            ? data?.selected_wood.map(
                                (options: any, index: number) => (
                                  <option
                                    key={index}
                                    value={options.wood_selection_option}
                                    selected={
                                      options.wood_selection_option ===
                                      data?.selectedWood
                                    }
                                  >
                                    {options.wood_selection_option}
                                  </option>
                                )
                              )
                            : data?.option_selected[0].wood_selection.map(
                                (options: any, index: number) => (
                                  <option
                                    key={index}
                                    value={options.wood_selection_option}
                                    selected={
                                      options.wood_selection_option ===
                                      data?.selectedWood
                                    }
                                  >
                                    {options.wood_selection_option}
                                  </option>
                                )
                              )}
                        </select>
                      </td>

                      {/* <td className="text-start">
                    <select
                      className="form-select form-select-solid"
                      data-kt-select2="true"
                      data-placeholder="Wood selection"
                      data-allow-clear="true"
                      defaultValue={data?.wood_selection[0]}
                    >
                      {data?.wood_selection.map((wood_selection: any, index: number) => (
                        <option key={index} value={wood_selection}>
                          {wood_selection}
                        </option>
                      ))}
                    </select>
                  </td> */}

                      <td className="text-start">
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary fs-6"
                        >
                          ${data?.sf_cost || data?.option_selected[0].sf_cost}
                        </a>
                      </td>

                      {page === "invoice" && (
                        <>
                          <td className="text-center">
                            <input
                              type="number"
                              min={0}
                              style={{ marginBottom: "5px" }}
                              // data-kt-user-table-filter='search'
                              className="form-control form-control-solid w-150px ps-14"
                              placeholder="area"
                              // value={fixture.unit || 0}
                              value={data?.area_sf || ""}
                              onChange={(e) =>
                                calculateCost(e.target.value, index)
                              }
                            />
                          </td>

                          <td className="text-start">
                            <a
                              href="#"
                              className="text-dark fw-bolder text-hover-primary fs-6"
                            >
                              ${data?.cost ? data?.cost?.toFixed(2) : 0}
                            </a>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}

              {page === "invoice" && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontWeight: 600 }} className="text-center">
                      Total
                    </td>
                    <td>
                      <a
                        href="#"
                        className="text-dark fw-bolder text-hover-primary fs-6"
                        style={{ paddingLeft: "2.5%" }}
                      >
                        ${total?.toFixed(2) || 0}
                      </a>
                    </td>
                  </tr>
                  {/* <tr style={{ marginTop: "10px" }}>
                  <td colSpan={3}>
                    <FormInput type='text' className='form-control form-control-solid' placeholder='Please enter comment here' onChange={(e) => handleComment(e.target.value)} />
                  </td>
                </tr> */}
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { HardwoodPage };

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Button } from "react-bootstrap";
import { toast, toastConfig, ToastOptions } from "../../../utils/toastService";

type Props = {
  tiles: any;
  handleModalClose: () => void;
};

const TilesInteractive: React.FC<Props> = ({ tiles, handleModalClose }) => {
  const tiles_data: any = tiles;
  const [tileSelected, setTileSelected]: any = useState({});
  const [areaSelected, setAreaSelected]: any = useState([]);
  const [optionSelected, setOptionSelected]: any = useState([]);
  const [tile, setTile]: any = useState("");
  const [optionSelectedValue, setOptionSelectedValue]: any = useState("");
  const [sfCost, setSfCost]: any = useState(0);
  const [areaSF, setAreaSF]: any = useState(0);
  const [totalCost, setTotalCost]: any = useState(0);
  const [tileImage, setTileImage]: any = useState("");
  const [selectedArea, setSelectedArea] = useState(null);

  const resetForm = () => {
    setTileSelected({});
    setAreaSelected([]);
    setOptionSelected([]);
    setTile("");
    setOptionSelectedValue("");
    setSfCost(0);
    setAreaSF(0);
    setTotalCost(0);
    setTileImage("");
    setSelectedArea(null);
  };

  const handleAreaChange = (tile: any) => {
    resetForm();

    if (tile.target.value !== "Select Area") {
      const parsedData = JSON.parse(tile.target.value);
      if (parsedData.area_sf) {
        setAreaSF(parsedData.area_sf);
      }
      setTileSelected(parsedData);
      setAreaSelected(parsedData?.option_selected);
      setSelectedArea(parsedData);
    }
  };

  const handleOptionSelectedChange = (tile: any) => {
    setSfCost(0);
    setOptionSelected([]);
    setTile("");
    const parsedData = JSON.parse(tile.target.value);
    if (areaSF) {
      const total = areaSF * Number(parsedData?.sf_cost);
      setTotalCost(total);
    }
    setSfCost(parsedData?.sf_cost);
    setOptionSelectedValue(parsedData?.option);
    setOptionSelected(parsedData.tile_selection);
  };

  const handleTileSelectedChange = (tile: any) => {
    if (tile.target.value !== "Select tile") {
      // let areaName = tileSelected.area;
      const parsedData = JSON.parse(tile.target.value);
      // let raw_image_name = `${areaName} ${optionSelectedValue} ${parsedData}`;
      // setTileImage(dynamicImage(raw_image_name));
      setTileImage(parsedData.image_src);
      setTile(parsedData.tile_selection_option);
    }
  };

  const calculateCost = (event: any) => {
    const total = sfCost * Number(event.target.value);
    setAreaSF(Number(event.target.value));
    setTotalCost(total);
  };

  const saveChanges = (e: any) => {
    e.preventDefault();
    let localStorageTiles: any = localStorage.getItem("tiles");
    let tempSavedTiles: any = JSON.parse(localStorageTiles);
    let savedTiles: any = tempSavedTiles.data;
    for (let i = 0; i < savedTiles.length; i++) {
      if (savedTiles[i]._id === tileSelected._id) {
        savedTiles[i].area_sf = areaSF;
        savedTiles[i].cost = totalCost;
        savedTiles[i].selectedOption = optionSelectedValue;
        savedTiles[i].selectedTile = tile;
        savedTiles[i].selected_tiles_option = optionSelected;
        savedTiles[i].sf_cost = sfCost;
      }
    }
    let localstorageData = {
      total: tempSavedTiles.total,
      data: savedTiles,
    };
    localStorage.setItem("tiles", JSON.stringify(localstorageData));
    resetForm();
    toast.success("Option selected successfully", toastConfig as ToastOptions);
  };
  return (
    <div className="row g-5 g-xxl-8">
      <div className="col-xl-8">
        <div className="card mb-5 mb-xxl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Image</span>
            </h3>
          </div>
          <div className="card-body text-center">
            <img
              alt="Interactive tile image"
              src={tileImage}
              style={{ width: "100%", cursor: "pointer" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = toAbsoluteUrl(`/media/images/default.png`);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="card mb-5 mb-xxl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                Option Selection For Tile
              </span>
            </h3>
          </div>
          <div className="card-body">
            <form>
              <div className="mb-10">
                <label className="form-label">Select Area</label>
                <select
                  className="form-select form-select-solid"
                  data-kt-select2="true"
                  data-placeholder="Select Area"
                  data-allow-clear="true"
                  value={
                    selectedArea ? JSON.stringify(selectedArea) : "Select Area"
                  }
                  // defaultValue={data?.selectedOption || data?.option_selected[0].option}
                  onChange={handleAreaChange}
                >
                  <option value="Select Area">Select Area</option>
                  {tiles_data.map((options: any, index: number) => (
                    <option key={index} value={JSON.stringify(options)}>
                      {options.area}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-10">
                <label className="form-label">Select Option</label>
                <select
                  className="form-select form-select-solid"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  // defaultValue={data?.selectedOption || data?.option_selected[0].option}
                  onChange={handleOptionSelectedChange}
                  disabled={Object.keys(areaSelected).length ? false : true}
                >
                  {areaSelected && areaSelected?.length ? (
                    areaSelected?.map((options: any, index: number) => (
                      <option key={index} value={JSON.stringify(options)}>
                        {options?.option}
                      </option>
                    ))
                  ) : (
                    <option>Select option</option>
                  )}
                </select>
              </div>
              <div className="mb-10">
                <label className="form-label">Select Tile</label>
                <select
                  className="form-select form-select-solid"
                  data-kt-select2="true"
                  data-placeholder="Select tile"
                  data-allow-clear="true"
                  // defaultValue={data?.selectedOption || data?.option_selected[0].option}
                  onChange={handleTileSelectedChange}
                  disabled={Object.keys(optionSelected).length ? false : true}
                >
                  <option value="Select tile">Select tile</option>
                  {optionSelected && optionSelected?.length ? (
                    optionSelected
                      ?.filter((options: any) => options.image_src)
                      .map((options: any, index: number) => (
                        <option key={index} value={JSON.stringify(options)}>
                          {options.tile_selection_option}
                        </option>
                      ))
                  ) : (
                    <option>Select tile</option>
                  )}
                </select>
              </div>
              <div className="mb-10">
                <label className="form-label">SF Cost</label>
                <input
                  type="number"
                  className="form-control form-control-solid"
                  placeholder="SF Cost"
                  value={sfCost || 0}
                  disabled
                />
              </div>
              <div className="mb-10">
                <label className="form-label">Area SF</label>
                <input
                  type="number"
                  className="form-control form-control-solid"
                  placeholder="Area (SF)"
                  onChange={calculateCost}
                  value={areaSF}
                  min="0"
                />
              </div>
              <div className="mb-10">
                <label className="form-label">Total Cost</label>
                <input
                  type="number"
                  className="form-control form-control-solid"
                  placeholder="Cost"
                  value={totalCost?.toFixed(2) || 0}
                  disabled
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="primary" onClick={saveChanges}>
                  Save
                </Button>
                <Button
                  style={{ marginLeft: "5px" }}
                  variant="secondary"
                  onClick={handleModalClose}
                >
                  Close
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TilesInteractive;
